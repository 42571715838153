import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  Form,
  DatePicker,
  Upload,
  Select,
  Checkbox,
  Switch,
  Button,
  Popover,
  notification,
  Spin,
} from "antd";
import { LoadingOutlined, InfoCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

import _service from "@netuno/service-client";
import Cluar from "../../../common/Cluar";
import { useNavigate, useLocation } from "react-router-dom";

import ContentQuill from "./ContentQuill";

import "./index.less";

const { Option } = Select;

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const CreatePost = ({ isEditing }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const uid = location.state?.uid;

  const [imageUrl, setImageUrl] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setSubmitLoading] = useState(false);

  const [areaFilters, setAreaFilters] = useState([
    { value: null, label: "Selecione uma opção" },
  ]);
  const [categoriesFilters, setCategoriesFilters] = useState([]);
  const [trlFilters, setTrlFilters] = useState([{ value: null, label: "" }]);

  const [defaultFormValues, setDefaultFormValues] = useState({
    title: "",
    date: dayjs(),
    content: "",
    image: null,
    area: null,
    categories: [],
    category: {},
    trl: null,
    is_strategic: false,
    is_external: false,
    description: "",
  });

  useEffect(() => {
    //console.log("UID", uid);

    if (isEditing && (uid == null || uid == "")) {
      navigate(`/`, { replace: true });
    } else if (isEditing) {
      getPostData();
    }

    getFilters();
    window.scrollTo(0, 0);
  }, []);

  const getImageFile = (url) => {
    return fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        return new File([blob], "featured_image.png", { type: "image/png" });
      })
      .catch((error) => {
        throw new Error("Error loading file");
      });
  };

  const getPostData = () => {
    _service({
      url: "/post",
      method: 'GET',
      data: {
        uid,
      },
      success: (response) => {
        const jsonData = response.json;

        if (jsonData.result) {
          const data = jsonData['data'];

          let categoryData = {};
          for (const category of data["category"]) {
            categoryData = { ...categoryData, [category.slug]: category.rank };
          }
          
          const postData = {
            title: data.title,
            date: dayjs(data.date),
            area: data["metadata"].area ? data["metadata"].area : null,
            category: categoryData,
            trl: data["metadata"].trl ? data["metadata"].trl : null,
            content: data["structure"][0]
              ? data["structure"][0].content
              : "",
            is_strategic: data["metadata"].strategic,
            is_external: data["metadata"].is_external,
            description: data.description,
          };
    
          form.setFieldsValue(postData);
          setDefaultFormValues(postData);
    
          const values = form.getFieldsValue();
          const fieldNames = Object.keys(values);

          if (data.featured_image) {
            setLoading(true);
            getImageFile(data.featured_image)
              .then((file) => {
                beforeUpload(file);
              })
              .catch((error) => {
                console.error(error);
              });
          }
        }
      },
      fail: (e) => {
        console.log("Service failed:", e);
        //resolve();
      }
    });

    /**Cluar.post(uid).then((postData) => {
      console.log("getPostData", postData);

      let categoryData = {};
      for (const category of postData["category"]) {
        categoryData = { ...categoryData, [category.slug]: category.rank };
      }

      const data = {
        title: postData.title,
        date: dayjs(postData.date),
        area: postData["metadata"].area ? postData["metadata"].area : null,
        categories: postData.categories,
        category: categoryData,
        trl: postData["metadata"].trl ? postData["metadata"].trl : null,
        content: postData["structure"][0]
          ? postData["structure"][0].content
          : "",
        is_strategic: postData["metadata"].strategic,
        is_external: postData["metadata"].is_external,
        description: postData.description,
      };

      form.setFieldsValue(data);
      setDefaultFormValues(data);

      const values = form.getFieldsValue();
      const fieldNames = Object.keys(values);
      console.log(fieldNames);

      if (postData.featured_image) {
        setLoading(true);
        getImageFile(postData.featured_image)
          .then((file) => {
            beforeUpload(file);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    });*/
  };

  const getFilters = () => {
    fetch(`/data/post-areas.json?time=${new Date().getTime()}`)
      .then((response) => response.json())
      .then((data) => {
        const areaFiltersData = [{ value: null, label: "Selecione uma opção" }];
        const dataObj = Object.entries(data);
        dataObj.map((values) => {
          const uid = values[0];
          const metadata = values[1];

          areaFiltersData.push({
            value: uid,
            label: metadata.name,
          });
        });

        setAreaFilters(areaFiltersData);
      });

    fetch(`/data/post-trl.json?time=${new Date().getTime()}`)
      .then((response) => response.json())
      .then((data) => {
        let trlFiltersData = [{ value: null, label: "" }];
        const dataObj = Object.entries(data);
        dataObj.map((values) => {
          const uid = values[0];
          const metadata = values[1];

          trlFiltersData.push({
            value: uid,
            label: metadata.code,
          });
        });

        trlFiltersData.sort((a, b) => {
          const regex = /\d+/g;
          const aName = a.label.match(regex);
          const bName = b.label.match(regex);
          const aNum = parseInt(aName);
          const bNum = parseInt(bName);

          if (aNum === bNum) {
            const aText = a.label.replace(regex, "").toLowerCase();
            const bText = b.label.replace(regex, "").toLowerCase();
            return aText.localeCompare(bText);
          } else {
            return aNum - bNum;
          }
        });

        setTrlFilters(trlFiltersData);
      });

    fetch(`/data/post-categories.json?time=${new Date().getTime()}`)
      .then((response) => response.json())
      .then((data) => {
        let categoriesData = [];
        const dataObj = Object.entries(data);
        dataObj.map((values) => {
          const uid = values[0];
          const metadata = values[1];

          categoriesData.push({
            value: uid,
            label: metadata.title,
            slug: metadata.slug,
          });
        });

        setCategoriesFilters(categoriesData);
      });
  };

  const uploadButton = (
    <div>
      {loading ? (
        <Spin className="create-post--form-item--upload--loading" />
      ) : (
        <img src="/images/capa.png" alt="Capa do Artigo" />
      )}
    </div>
  );

  const [form] = Form.useForm();

  const onFinishFailed = (e) => {
    window.scrollTo(0, 0);
  };

  const reduceBase64Img = async (base64Str, MAX_WIDTH = 450, MAX_HEIGHT = 450) => {
    let resized_base64 = await new Promise((resolve) => {
      let img = new Image();
      img.src = base64Str;
      img.onload = () => {
          let canvas = document.createElement('canvas');
          let width = img.width;
          let height = img.height;

          if (width > height) {
              if (width > MAX_WIDTH) {
                  height *= MAX_WIDTH / width;
                  width = MAX_WIDTH;
              }
          } else {
              if (height > MAX_HEIGHT) {
                  width *= MAX_HEIGHT / height;
                  height = MAX_HEIGHT;
              }
          }

          canvas.width = width;
          canvas.height = height;
          let ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);
          resolve(canvas.toDataURL());
      }
    });
    return resized_base64;
  }

  const onFinish = async (values) => {
    setSubmitLoading(true);
    //window.scrollTo(0, 0);

    const formattedDate = dayjs(values.date).format("YYYY-MM-DD");
    values.date = formattedDate;

    // Content Base64 Minify
    const updateMinifiedHtml = async () => {
      const content = values.content;
      const imgTags = content.match(/<img[^>]+>/g);

      let minifiedHtmlString = content;

      if (imgTags) {
        for (const imgTag of imgTags) {
          const srcMatch = imgTag.match(/src="([^"]+)"/);
          if (srcMatch) {
            const imageSrc = srcMatch[1];

            if (imageSrc.startsWith('data:image')) {
              const minifiedBase64Data = await reduceBase64Img(imageSrc);
            }
          }
        }
      }

      return minifiedHtmlString;
    };

    let finalMinifyHtmlString = await updateMinifiedHtml();
    values.content = finalMinifyHtmlString;

    _service({
      url: "/post",
      method: !isEditing ? "POST" : "PUT",
      data: {
        data: { ...values, uid: uid },
      },
      success: (response) => {
        setSubmitLoading(false);
        const data = response.json;

        notification.success({
          description: `O artigo foi ${
            !isEditing ? "publicado" : "editado"
          } com sucesso.`,
        });

        if (isEditing) {
          navigate(`${Cluar.getCurrentLanguageLink()}/gerir-artigos`);
        } else {
          navigate(`${Cluar.getCurrentLanguageLink()}/`);
        }

        /**if (data.result) {
          notification.success({
            description: `O artigo foi ${
              !isEditing ? "publicado" : "editado"
            } com sucesso.`,
          });

          if (isEditing) {
            navigate(`${Cluar.getCurrentLanguageLink()}/gerir-artigos`);
          } else {
            navigate(`${Cluar.getCurrentLanguageLink()}/`);
          }
        } else {
          notification.error({
            description: `Não foi possível ${
              !isEditing ? "publicar" : "editar"
            } o artigo.`,
          });
        }*/
      },
      fail: (e) => {
        setSubmitLoading(false);
        notification.error({
          description: `Não foi possível ${
            !isEditing ? "publicar" : "editar"
          } o artigo.`,
        });
        setLoading(false);
        console.log("Service failed:", e);
      },
    });
  };

  const minifyImage = (base64String) => {
    const img = new Image();
    img.src = base64String;
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      canvas.toBlob(
        (blob) => {
          const newReader = new FileReader();
          newReader.onload = () => {
            const base64String = newReader.result;

            setLoading(false);
            setImageUrl(base64String);
            form.setFieldValue("image", base64String);
          };
          newReader.readAsDataURL(blob, 0.8); // set quality to 50%
        },
        "image/jpeg",
        0.8
      ); // set quality to 50% and use JPEG format
    };
  };

  const beforeUpload = (file) => {
    setLoading(true);

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        minifyImage(reader.result);
      };
      reader.readAsDataURL(file);
      reader.onerror = () => {
        reject(new Error("Failed to read file"));
      };
    });
  };

  return (
    <Form
      name="basic"
      form={form}
      initialValues={defaultFormValues}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row className="create-post" justify="center" gutter={24}>
        <Col span={16} className="post-list--col-left">
          <div className="title">
            {!isEditing ? <h1>Criar Novo Artigo</h1> : <h1>Editar Artigo</h1>}
          </div>
          <Form.Item
            name="title"
            rules={[{ required: true, message: "O título é obrigatório!" }]}
          >
            <Input placeholder="Título do artigo" />
          </Form.Item>
          <Form.Item
            label="Data do Artigo"
            className="create-post--form-item"
            name="date"
            labelCol={{ span: 24 }}
          >
            <DatePicker format="DD/MM/YYYY" />
          </Form.Item>
          <Form.Item name="content">
            <ContentQuill defaultContent={defaultFormValues["content"]} />
          </Form.Item>
          <Form.Item
            name="description"
            label="Descrição do Artigo"
            className="create-post--form-item"
            labelCol={{ span: 24 }}
          >
            <Input.TextArea rows={5} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="image" className="image">
            <h1>Capa do Artigo</h1>
            <Upload
              listType="picture-card"
              showUploadList={false}
              //action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              beforeUpload={beforeUpload}
              //onChange={handleChange}
              src={imageUrl}
            >
              {imageUrl ? <img src={imageUrl} /> : uploadButton}
            </Upload>
          </Form.Item>
          <div className="classification filters">
            <h1>Classificação do Artigo</h1>
            <Form.Item
              name="area"
              label="Área"
              initialValue="all"
              className="create-post--form-item"
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Campo obrigatório",
                },
              ]}
            >
              <Select
                popupClassName="filters--form--item--select--dropdown"
                options={areaFilters}
              />
            </Form.Item>
            <Row className="category">
              {categoriesFilters.map((category) => (
                <Col className="category--item">
                  <Form.Item
                    name={["category", category.slug]}
                    label={
                      <label>
                        {category.label}{" "}
                        {
                          <Popover
                            placement="bottom"
                            content={
                              <div>
                                <p>1 – Baixo</p>
                                <p>2 – Suficiente</p>
                                <p>3 – Bom</p>
                                <p>4 – Muito bom</p>
                                <p>5 – Forte</p>
                              </div>
                            }
                          >
                            <InfoCircleOutlined style={{ fontSize: 14 }} />
                          </Popover>
                        }
                      </label>
                    }
                    className="create-post--form-item select-category"
                    rules={[
                      { required: true, message: "Campo é obrigatório!" },
                    ]}
                  >
                    <Select>
                      {[1, 2, 3, 4, 5].map((number) => (
                        <Option key={number} value={number}>
                          {number}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              ))}
            </Row>
            <Form.Item
              name="trl"
              label="Prontidão tecnológica vs Tempo para o mercado"
              className="create-post--form-item trl"
              rules={[{ required: true, message: "Campo é obrigatório!" }]}
            >
              <Select
                popupClassName="filters--form--item--select--dropdown"
                options={trlFilters}
              />
            </Form.Item>
            <Form.Item
              label="Artigo Estratégico"
              valuePropName="checked"
              labelCol={{ span: 24 }}
              name="is_strategic"
              className="create-post--form-item"
            >
              <Switch className="create-post--form-item--switch" />
            </Form.Item>
            <Form.Item
              label="Artigo Externo"
              valuePropName="checked"
              labelCol={{ span: 24 }}
              name="is_external"
              className="create-post--form-item"
            >
              <Switch className="create-post--form-item--switch" />
            </Form.Item>
            <Form.Item label="">
              {!isEditing ? (
                <Button
                  className="create-post--form-item--submit"
                  type="primary"
                  htmlType="submit"
                  loading={loadingSubmit}
                >
                  Publicar Artigo
                </Button>
              ) : (
                <Button
                  className="create-post--form-item--submit"
                  type="primary"
                  htmlType="submit"
                  loading={loadingSubmit}
                >
                  Salvar Artigo
                </Button>
              )}
            </Form.Item>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default CreatePost;
