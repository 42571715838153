import React, { useState, useEffect } from "react";

import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Switch,
  DatePicker,
  Checkbox,
  Slider,
  Popover,
} from "antd";
import { LoadingOutlined, InfoCircleOutlined } from "@ant-design/icons";

import Cluar from "../../../../common/Cluar";

import "./index.less";

const FiltersManagement = (props) => {
  const [areaFilters, setAreaFilters] = useState([]);
  const [categoryFilters, setCategoryFilters] = useState([]);
  const [trlFilters, setTrlFilters] = useState([
    { value: "all", label: "Todos" },
  ]);
  const [filters, setFilters] = useState({
    order: "latest",
    only_strategic: false,
    only_external: false,
    area: "all",
    trl: "all",
    category: {},
    range: [],
    search_string: "",
  });

  const filtersCategory = (val, uid) => {
    console.log("uid", uid);
    console.log("val", val);

    setFilters({
      ...filters,
      category: {
        ...filters.category,
        [uid]: val,
      },
    });
  };

  useEffect(() => {
    getFilters();
    props.handleAreaCategories({ areaFilters, categoryFilters });
  }, []);

  /**useEffect(() => {
    let defaultCategoryFilter = {};

    categoryFilters.map((category, index) => {
      defaultCategoryFilter = {
        ...defaultCategoryFilter,
        [category.value]: [1, 5],
      };
    });

    console.log("defaultCategoryFilter", defaultCategoryFilter);

    setFilters({
      ...filters,
      category: defaultCategoryFilter,
    });

    form.setFieldValue("category", defaultCategoryFilter);
    props.handleAreaCategories({ areaFilters, categoryFilters });
  }, [categoryFilters]);*/

  const getFilters = () => {
    /** AREAS */
    Cluar.areas().then((data) => {
      if (data) {
        const areaFiltersData = [];
        const dataObj = Object.entries(data);
        dataObj.map((values) => {
          const uid = values[0];
          const metadata = values[1];

          areaFiltersData.push({
            value: uid,
            label: metadata.name,
          });
        });

        const checkIfUpdate =
          JSON.stringify(areaFilters) !== JSON.stringify(areaFiltersData);

        if (checkIfUpdate) {
          setAreaFilters(areaFiltersData);
        }
      }
    });

    /** Categories */
    /**Cluar.categories().then((data) => {

      if (data) {
        let categoriesData = [];
        const dataObj = Object.entries(data);
        dataObj.map((values) => {
          const uid = values[0];
          const metadata = values[1];

          categoriesData.push({
            value: uid,
            label: metadata.title,
          });
        });

        const checkIfUpdate = JSON.stringify(categoryFilters) !== JSON.stringify(categoriesData);

        if (checkIfUpdate) {
          setCategoryFilters(categoriesData);
        }
      }
    });*/

    fetch(`/data/post-categories.json?time=${new Date().getTime()}`)
      .then((response) => response.json())
      .then((data) => {
        let dataResponse = [];
        const dataObj = Object.entries(data);
        dataObj.map((values) => {
          const uid = values[0];
          const metadata = values[1];

          dataResponse.push({
            value: uid,
            label: metadata.title,
            slug: metadata.slug,
          });

          setFilters({
            ...filters,
            category: {
              ...filters.category,
              [uid]: [1, 5],
            },
          });
        });

        setCategoryFilters(dataResponse);
      });

    /** TRLs */
    Cluar.trl().then((data) => {
      if (data) {
        let trlFiltersData = [{ value: null, label: "Todos" }];
        const dataObj = Object.entries(data);
        dataObj.map((values) => {
          const uid = values[0];
          const metadata = values[1];

          trlFiltersData.push({
            value: uid,
            label: metadata.code,
          });
        });

        trlFiltersData.sort((a, b) => {
          const regex = /\d+/g;
          const aName = a.label.match(regex);
          const bName = b.label.match(regex);
          const aNum = parseInt(aName);
          const bNum = parseInt(bName);

          if (aNum === bNum) {
            const aText = a.label.replace(regex, "").toLowerCase();
            const bText = b.label.replace(regex, "").toLowerCase();
            return aText.localeCompare(bText);
          } else {
            return aNum - bNum;
          }
        });

        const checkIfUpdate =
          JSON.stringify(trlFilters) !== JSON.stringify(trlFiltersData);

        if (checkIfUpdate) {
          setTrlFilters(trlFiltersData);
        }
      }
    });
  };

  const defaultFormValues = {
    order: "latest",
    only_strategic: false,
    only_external: false,
    area: [],
    trl: null,
    category: {},
    range: [],
    search_string: "",
  };

  const handleFormChange = (values, allValues) => {
    console.log("Filters CHANGED!!");
    props.onChange(allValues);
  };

  const [form] = Form.useForm();

  return (
    <Form
      form={form}
      className="filters-management"
      layout="inline"
      labelWrap={false}
      onValuesChange={handleFormChange}
      initialValues={defaultFormValues}
    >
      <Row wrap={true} style={{ width: "100%" }} justify="center">
        <Col className="row-selects">
          <Col span={7}>
            <Form.Item label="Buscar artigo" name="search_string">
              <Input placeholder="Busque por um artigo publicado" allowClear />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              name="range"
              label="Filtrar por período"
              className="filters--form--item"
            >
              <DatePicker.RangePicker
                popupClassName="date-range-picker"
                placeholder={["aaaa-mm-dd", "aaaa-mm-dd"]}
              />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              name="area"
              label="Áreas"
              className="radar-chart--filters-col--filters--form--item"
            >
              <Select
                //onChange={filtersArea}
                popupClassName="radar-chart--filters-col--filters--form--item--select--dropdown"
                options={areaFilters}
                mode="multiple"
                placeholder="Todas"
                allowClear={true}
              />
            </Form.Item>
          </Col>
          <Col span={3}></Col>
        </Col>
        <Col className="row-selects" span={24}>
          <Col span={6}>
            <Form.Item
              name="order"
              label="Ordenar por"
              initialValue="alphabetical"
            >
              <Select
                popupClassName="filters-management--select--dropdown"
                options={[
                  { value: "latest", label: "Artigos mais recentes" },
                  { value: "alphabetical", label: "Ordenação de A - Z" },
                  { value: "strategic", label: "Artigos Estratégicos" },
                  { value: "popular", label: "Artigos mais visualizados" },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="trl"
              label="Prontidão tecnológica vs Tempo para o mercado"
              initialValue="all"
              className="filters--form--item trl"
            >
              <Select
                popupClassName="filters--form--item--select--dropdown"
                options={trlFilters}
              />
            </Form.Item>
          </Col>
        </Col>
        <Col
          className="row-categories"
          style={{ marginTop: 10, flexWrap: "wrap" }}
        >
          {categoryFilters.map((category) => (
            <Col className="col-categories">
              <Form.Item
                name={["category", category.value]}
                label={<label>{category.label} </label>}
              >
                <Slider
                  range={true}
                  defaultValue={[1, 5]}
                  initialValue={[1, 5]}
                  max={5}
                  min={1}
                  onChange={(val) => filtersCategory(val, category.value)}
                />
              </Form.Item>
            </Col>
          ))}
        </Col>
      </Row>
      {/* </Form.Item>
      <Form.Item label="">
        <Input />
      </Form.Item>
      <Form.Item label="">
        <Input />
      </Form.Item>
      <Form.Item label="">
        <Input />
      </Form.Item> */}
    </Form>
  );
};

export default FiltersManagement;
