import React, { useState, useEffect, createContext } from "react";
import { ConfigProvider, Layout, Spin } from "antd";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useParams,
} from "react-router-dom";

import Analytics from "./common/Analytics";
import Cluar from "./common/Cluar";
import Builder from "./common/Builder";
import BaseCookies from "./base/Cookies";
import BaseHeader from "./base/Header";
import BaseFooter from "./base/Footer";
import NotFound from "./pages/NotFound";

import "./styles/App.less";

// create a context object
export const DataContext = createContext();

const { Content } = Layout;

function App() {
  let urlLang = null;
  if ((urlLang = /^\/([a-z]+)\//gi.exec(window.location.pathname))) {
    Cluar.changeLanguage(urlLang[1]);
  } else {
    const storageLocale = window.localStorage.getItem("locale");
    if (storageLocale == null) {
      Cluar.changeLanguage(Cluar.defaultLanguage().locale);
    } else {
      Cluar.changeLanguage(storageLocale);
    }
  }

  const initialFilters = {
    order: "latest",
    area: [],
    trl: null,
    only_strategic: false,
    only_external: false,
    date: null,
    category: {},
    range: [],
    searchString: "",
  };

  const [routes, setRoutes] = useState([]);
  const [reloadRadarChart, setReloadRadarChart] = useState(0);
  const [filters, setFilters] = useState(initialFilters);

  useEffect(() => {
    getRoutes();
  }, []);

  const resetFilters = () => {
    setFilters(initialFilters);
  };

  const getRoutes = () => {
    console.log("getRoutes");

    const newRoutes = [];
    for (const language of Cluar.languages()) {
      const index = Cluar.languages().indexOf(language);

      let localeURL = "";
      if (Cluar.languages().length > 1) {
        localeURL = `/${language.locale}`;
      }

      const subroutes = [];

      if (!Cluar.pages()[language.code]) {
        return;
      }

      for (const page of Cluar.pages()[language.code]) {
        if (page.navigable === false || page.link.indexOf("//") >= 0) {
          continue;
        }

        subroutes.push(
          <Route
            key={`${localeURL}${page.link}`}
            path={`${localeURL}${page.link}`}
            exact
            element={
              <Builder
                page={page}
                type={"page"}
              />
            }
          />
        );
      }
      console.log("subroutes", subroutes);
      newRoutes.push(
        <Route key={`${localeURL}/`} path={`${localeURL}/`}>
          {subroutes}
        </Route>
      );

      /** POSTS */
      newRoutes.push(
        <Route key={`posts`} path="*" element={<Builder
          type={"post"}
        />} />
      );
      
      setRoutes(newRoutes);

      /**Cluar.posts()
        .then((posts) => {
          if (!posts[language.code]) {
            return;
          }

          for (const post of posts[language.code]) {
            if (post.indexOf("//") >= 0) {
              continue;
            }

            subroutes.push(
              <Route
                key={`${localeURL}${post}`}
                path={`${localeURL}${post}`}
                exact
                element={
                  <Builder
                    page={post}
                    type={"post"}
                    updateRoutes={updateRoutes}
                  />
                }
              />
            );
          }

          return true;
          //return Cluar.pages();
        })
        .then((pages) => {
          //console.log("pages", pages);

          if (!Cluar.pages()[language.code]) {
            return;
          }

          for (const page of Cluar.pages()[language.code]) {
            if (page.navigable === false || page.link.indexOf("//") >= 0) {
              continue;
            }

            subroutes.push(
              <Route
                key={`${localeURL}${page.link}`}
                path={`${localeURL}${page.link}`}
                exact
                element={
                  <Builder
                    page={page}
                    type={"page"}
                    updateRoutes={updateRoutes}
                  />
                }
              />
            );
          }

          return true;
        })
        .then(() => {
          newRoutes.push(
            <Route key={`${localeURL}/`} path={`${localeURL}/`}>
              {subroutes}
            </Route>
          );

          if (index + 1 === Cluar.languages().length) {
            let checkIfUpdate = false;

            if (update) {
              checkIfUpdate =
                JSON.stringify(oldRoutes) !== JSON.stringify(newRoutes);
            } else {
              checkIfUpdate =
                JSON.stringify(routes) !== JSON.stringify(newRoutes);
            }

            console.log("checkIfUpdate", checkIfUpdate);

            if (checkIfUpdate) {
              setRoutes(newRoutes);
            }
          }
        });
        */


    }
  };

  const defaultRoute = [];
  if (Cluar.languages().length > 1) {
    defaultRoute.push(
      <Route
        path="/"
        exact
        element={<Navigate to={`/${Cluar.currentLanguage().locale}/`} />}
      />
    );
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#000",
          fontSize: 16,
          borderRadius: 20,
        },
      }}
    >
      <BrowserRouter>
        {Cluar.isGAEnabled() && <Route component={Analytics} />}
        <div className="page">
          <DataContext.Provider
            value={{
              routes,
              filters,
              setFilters,
              reloadRadarChart,
              setReloadRadarChart,
              resetFilters,
            }}
          >
            <Layout>
              <BaseHeader />
              <Content>
                {routes.length > 0 ? (
                  <Routes>
                    {routes}
                    <Route path="/404" element={<NotFound />} />
                  </Routes>
                ) : (
                  <div className="loading">
                    <div className="spinner-container">
                      <Spin size="large" />
                    </div>
                  </div>
                )}
              </Content>
              <BaseFooter />
              {/* <BaseCookies /> */}
            </Layout>
          </DataContext.Provider>
        </div>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
