import React, { useState } from 'react';
import { Layout, Row, Col, Menu } from "antd";
import { ConsoleSqlOutlined, GlobalOutlined } from '@ant-design/icons';
import Burger from '@animated-burgers/burger-slip';
import '@animated-burgers/burger-slip/dist/styles.css';
import classNames from 'classnames';
import {
  Route,
  Link
} from "react-router-dom";
import Cluar from '../../common/Cluar';
import TermsConditionsButton from "../../components/TermsConditionsButton";

import "./index.less";

const { Footer } = Layout;

function BaseFooter() {
  const [burgerMenu, setBurgerMenu] = useState(false);
  const [activeMenu, setActiveMenu] = useState('main');

  const handleMenuClick = (selectMenu) => {
    setBurgerMenu(false);
    if (selectMenu) {
      setActiveMenu(selectMenu);
    }
    window.scrollTo(0, 0);
  };

  const menu = [];
  const subMenuKeys = [];
  const routes = [];

  for (const language of Cluar.languages()) {
    if (!Cluar.pages()[language.code]) {
      continue;
    }

    const buildMenu = (page) => {
      if (language.code === Cluar.currentLanguage().code) {
        const key = `${page.link}`;

        subMenuKeys.push(key);

        if (!page.parent && page.menu_footer) {
          return ({
            label: page.navigable ? (
              page.link.indexOf('//') >= 0 ? (
                <a key={page.link} href={`${page.link}`} target="_blank">{page.title}</a>
              ) : (
                <Link key={page.link} to={`${Cluar.getCurrentLanguageLink()}${page.link}`} onClick={() => handleMenuClick(key)}>
                  {page.title}
                </Link>
              )
            ) : (
              <a key={page.link}>{page.title}</a>
            ),
            key,
          });
        }
      }
      return;
    };

    const subroutes = [];
    for (const page of Cluar.pages()[language.code]) {

      if (page.parent === "" && language.code === Cluar.currentLanguage().code && page.menu_footer) {
        menu.push(
          buildMenu(page, 0)
        );
      }
    }

  }

  return (
    <Footer className="ant-layout-footer">
      <Row
        className="ant-layout-footer__wrapper"
        align="middle"
        justify="space-between"
        gutter={[0, 10]}
      >
        <Col>
          <img src="/images/radartech.PNG" alt="logo"  style={{ width: 200 }}/>
        </Col>
        <Col>
          <a href="http://raiz-iifp.pt/instituto/" target="_blank" rel="noopener noreferrer" >
            Instituto
          </a>
          <a href="http://raiz-iifp.pt/equipa-de-investigacao/" target="_blank" rel="noopener noreferrer" >
            Pessoas
          </a>
          <a href="http://raiz-iifp.pt/atividades/" target="_blank" rel="noopener noreferrer" >
            Atividades
          </a>
          <a href="http://raiz-iifp.pt/projetos/" target="_blank" rel="noopener noreferrer" >
            Projetos
          </a>
        </Col>
      </Row>
      <hr />
      <Row
        className="ant-layout-footer__wrapper"
        align="middle"
        justify="space-between"
        gutter={[0, 10]}
      >
        <Col>
          <span>RADARtech &copy; 2023</span>
        </Col>
        <Col>
          <TermsConditionsButton />
          {/* <a href="http://raiz-iifp.pt/politica-de-privacidade/" target="_blank" rel="noreferrer">Política de Privacidade</a> */}
          {/* <a href="http://raiz-iifp.pt/politica-de-cookies/" target="_blank" rel="noreferrer">Cookies</a> */}
        </Col>
      </Row>
      <hr />
      <Row
        className="ant-layout-footer__wrapper"
        align="middle"
        justify="space-between"
        gutter={[0, 10]}
      >
        <Col span={24} style={{ textAlign: "center", marginBottom: 10 }}>
          <span>Cofinanciado por:</span>
        </Col>
        <Col span={24} style={{ textAlign: "center" }} className="image-co-financing">
          <img src='/images/footer-cofinanciado.png' ></img>
        </Col>
      </Row>
    </Footer>
  );
}

export default BaseFooter;
