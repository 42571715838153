import React from "react";
import { Button, Modal, Tabs } from "antd";
import CategoriesTab from "./CategoriesTab";
import AreasTab from "./AreasTab";

import "./index.less";

const AreasCategoriesModal = ({ show, onClose }) => {
  const items = [
    {
      key: "areas",
      label: `Áreas`,
      children: <AreasTab />,
    },
    {
      key: "categories",
      label: `Categorias`,
      children: <CategoriesTab />,
    }
  ];

  return (
    <Modal
      className="modal-areas-categories"
      title="Definições de Instituições e Áreas"
      open={show}
      onCancel={onClose}
      destroyOnClose={true}
      footer={
        <Button onClick={onClose} type="link">
          Fechar
        </Button>
      }
    >
      <Tabs defaultActiveKey="1" items={items} />
    </Modal>
  );
};

export default AreasCategoriesModal;
