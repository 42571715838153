import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Table,
  Input,
  Row,
  Col,
  Form,
  Popconfirm,
  InputNumber,
  Spin,
  notification,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  WarningOutlined,
  CloseOutlined,
  SaveOutlined,
} from "@ant-design/icons";

import _auth from "@netuno/auth-client";
import _service from "@netuno/service-client";
import { DataContext } from "../../../App";

import "./index.less";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === "number" ? (
      <InputNumber />
    ) : (
      <Input style={{ textAlign: "center" }} />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
            textAlign: "center",
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const AreasTab = () => {
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingAddData, setLoadingAddData] = useState(false);
  const [editingKey, setEditingKey] = useState("");
  const { reloadRadarChart, setReloadRadarChart } = useContext(DataContext);

  const [formEdit] = Form.useForm();
  const [formAdd] = Form.useForm();

  const isEditing = (record) => record.uid === editingKey;

  const editArea = (record) => {
    formEdit.setFieldsValue({
      name: record.name,
    });
    setEditingKey(record.uid);
  };

  useEffect(() => {
    getAreaData();
  }, []);

  const createArea = ({ name }) => {
    setLoadingAddData(true);

    _service({
      url: "/area",
      method: "POST",
      data: {
        name,
      },
      success: (response) => {
        console.log("response", response);
        const data = response.json;

        if (data.result) {
          notification.success({
            description: "A área foi criada com sucesso.",
          });

          formAdd.resetFields();
          getAreaData();
        } else {
          notification.error({
            description: "Não foi possível criar a área.",
          });
        }

        setReloadRadarChart(reloadRadarChart + 1);
        setLoadingAddData(false);
      },
      fail: (e) => {
        setLoadingAddData(false);
        console.log("Service failed:", e);

        if (e.status === 409) {
          notification.error({
            description: "Este item já existe.",
          });
        } else {
          notification.error({
            description: "Não foi possível criar a área.",
          });
        }
      },
    });
  };

  const getAreaData = () => {
    setLoadingData(true);
    fetch(`/data/post-areas.json?time=${new Date().getTime()}`)
      .then((response) => response.json())
      .then((data) => {
        const areaData = [];
        const dataObj = Object.entries(data);
        dataObj.map((values) => {
          const uid = values[0];
          const metadata = values[1];

          areaData.push({
            uid: uid,
            name: metadata.name,
          });
        });

        areaData.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          return 0;
        });

        setData(areaData);
        setEditingKey("");
        setLoadingData(false);
      });
  };

  const updateArea = (record) => {
    const newValue = formEdit.getFieldValue("name");
    setLoadingData(true);

    if (newValue !== record.name) {
      _service({
        url: "/area",
        method: "PUT",
        data: {
          uid: record.uid,
          name: newValue,
        },
        success: (response) => {
          const data = response.json;

          if (data.result) {
            notification.success({
              description: "A área foi atualizada com sucesso.",
            });

            getAreaData();
          } else {
            notification.error({
              description: "Não foi possível atualizar a área.",
            });
          }

          setReloadRadarChart(reloadRadarChart + 1);
          setLoadingData(false);
        },
        fail: (e) => {
          setLoadingData(false);
          console.log("Service failed:", e);

          if (e.status === 409) {
            notification.error({
              description: "Este item já existe.",
            });
          } else {
            notification.error({
              description: "Não foi possível atualizar a área.",
            });
          }
        },
      });
    }
  };

  const deleteArea = (record) => {
    setLoadingData(true);

    _service({
      url: "/area",
      method: "DELETE",
      data: {
        uid: record.uid,
      },
      success: (response) => {
        const data = response.json;

        if (data.result) {
          notification.success({
            description: "A área foi apagada com sucesso.",
          });

          getAreaData();
        } else {
          notification.error({
            description: "Não foi possível apagar a área.",
          });
        }

        setReloadRadarChart(reloadRadarChart + 1);
        setLoadingData(false);
      },
      fail: (e) => {
        setLoadingData(false);
        notification.error({
          description: "Não foi possível apagar a área.",
        });
        console.log("Service failed:", e);
      },
    });
  };

  const columns = [
    {
      title: "Áreas",
      dataIndex: "name",
      key: "name",
      editable: true,
    },
    {
      title: "Ações",
      key: "actions",
      render: (_, record) => {
        const editable = isEditing(record);
        return !editable ? (
          <div>
            <Button
              className="edit-area-btn"
              type="link"
              onClick={() => editArea(record)}
            >
              <EditOutlined style={{ fontSize: 20, color: "#2D2D2D" }} />
            </Button>
            <Popconfirm
              placement="top"
              title="Tem certeza que deseja eliminar esta área?"
              onConfirm={() => deleteArea(record)}
              okText="Sim"
              cancelText="Não"
              okType="link"
              okButtonProps={{
                className: "pop-confirm-confirm-btn",
              }}
              cancelButtonProps={{
                className: "pop-confirm-cancel-btn",
              }}
              icon={<WarningOutlined style={{ color: "#E70000" }} />}
              rootClassName="pop-confirm"
            >
              <Button className="delete-area-btn" type="link">
                <DeleteOutlined
                  style={{ fontSize: 20, color: "rgba(255, 0, 0, 0.6)" }}
                />
              </Button>
            </Popconfirm>
          </div>
        ) : (
          <div>
            <Button
              className="save-area-btn"
              type="link"
              onClick={() => updateArea(record)}
            >
              <SaveOutlined style={{ fontSize: 20, color: "#2D2D2D" }} />
            </Button>
            <Button type="link" onClick={() => setEditingKey("")}>
              <CloseOutlined
                style={{ fontSize: 20, color: "rgba(255, 0, 0, 0.6)" }}
              />
            </Button>
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <div className="areas-tab">
      <Form form={formAdd} onFinish={createArea}>
        <Row gutter={16} className="form-col">
          <Col span={12}>
            <Form.Item
              name="name"
              rules={[{ required: true, message: "Insira o nome da área." }]}
            >
              <Input placeholder="Nova área" className="input-default" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item>
              <Button
                id="add-area"
                type="primary"
                htmlType="submit"
                loading={loadingAddData}
              >
                Adicionar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Form form={formEdit} component={false}>
        <Table
          className="areas-tab-table"
          rowClassName="editable-row"
          columns={mergedColumns}
          dataSource={data}
          sticky={true}
          pagination={false}
          loa
          rowKey="uid"
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          loading={loadingData}
        />
      </Form>
    </div>
  );
};

export default AreasTab;
