import React, { useEffect, useState, useRef, useContext } from "react";
import dayjs from "dayjs";
import {
  Row,
  Col,
  Slider,
  Form,
  Switch,
  DatePicker,
  Select,
  Checkbox,
  Tree,
  Popover,
} from "antd";
import {
  Chart,
  Annotation,
  Point,
  Interval,
  Legend,
  Tooltip,
  Axis,
  Coordinate,
  Coord,
  G2,
  Geom,
} from "bizcharts";
import {
  PlusOutlined,
  MinusOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";

import { DataContext } from "../../../App";
import _service from "@netuno/service-client";

import "./index.less";
import FiltersManagement from "../PostsManagement/FiltersManagement";

const RadarChart = () => {
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 24 });
  const [initialPosition, setInitialPosition] = useState({ x: 0, y: 24 });
  const [scale, setScale] = useState(1);
  const [dataAreas, setDataAreas] = useState([]);
  const [dataCategories, setDataCategories] = useState([]);
  const [dataTrl, setDataTrl] = useState([]);
  const [dataChart, setDataChart] = useState([]);
  const { filters, setFilters, reloadRadarChart, resetFilters } =
    useContext(DataContext);
  const draggableRef = useRef(null);

  const onZoom = (val) => {
    setScale(1 + val / 100);
  };

  function getRandomArea(a, b) {
    return Math.random() * (b - a) + a;
  }

  const getData = () => {
    fetch(`/data/post-areas.json?time=${new Date().getTime()}`)
      .then((response) => response.json())
      .then((data) => {
        const dataResponse = [];
        const dataObj = Object.entries(data);
        dataObj.map((values, index) => {
          const uid = values[0];
          const metadata = values[1];

          const positionX = dataObj.length / 2;
          const labelLan = metadata.name.length;
          const positionByIndex = index + 0.5;
          const hasOffsetX = positionByIndex !== positionX;

          let offsetX = 0;

          if (hasOffsetX && positionByIndex > positionX) {
            offsetX = index === dataObj.length - 1 ? -100 : -50;
          } else if (hasOffsetX && positionByIndex < positionX) {
            offsetX = index === 0 ? 40 : 20;
            offsetX = 20 - labelLan * (index / 4);
          } else if (!hasOffsetX) {
            offsetX = -labelLan * 2;
          }

          dataResponse.push({
            value: uid,
            label: metadata.name,
            offsetX,
          });
        });

        setDataAreas(dataResponse);

        _service({
          method: "GET",
          url: "/chart",
          success: (response) => {
            //console.log(response);
            const dataFormat = response.json.data.map((item) => {
              const area_value = dataResponse.findIndex(
                (point) => point.label === item.area
              );

              return {
                ...item,
                area_value: getRandomArea(area_value + 0.2, area_value + 0.8),
                trl_value: getRandomArea(item.trl + 0.2, item.trl + 0.8),
                rank_value: item.rank * 4,
              };
            });
            setDataChart(dataFormat);
          },
          fail: (error) => console.log(error),
        });
      });

    fetch(`/data/post-trl.json?time=${new Date().getTime()}`)
      .then((response) => response.json())
      .then((data) => {
        let dataTrlResponse = [{ value: null, label: "Todos" }];
        const dataObj = Object.entries(data);
        dataObj.map((values) => {
          const uid = values[0];
          const metadata = values[1];

          dataTrlResponse.push({
            value: uid,
            label: metadata.code,
          });
        });

        dataTrlResponse.sort((a, b) => {
          const regex = /\d+/g;
          const aName = a.label.match(regex);
          const bName = b.label.match(regex);
          const aNum = parseInt(aName);
          const bNum = parseInt(bName);

          if (aNum === bNum) {
            const aText = a.label.replace(regex, "").toLowerCase();
            const bText = b.label.replace(regex, "").toLowerCase();
            return aText.localeCompare(bText);
          } else {
            return aNum - bNum;
          }
        });

        setDataTrl(dataTrlResponse);
      });

    fetch(`/data/post-categories.json?time=${new Date().getTime()}`)
      .then((response) => response.json())
      .then((data) => {
        let dataResponse = [];
        const dataObj = Object.entries(data);
        dataObj.map((values) => {
          const uid = values[0];
          const metadata = values[1];

          dataResponse.push({
            value: uid,
            label: metadata.title,
            slug: metadata.slug,
          });
        });

        setDataCategories(dataResponse);
      });
  };

  const filtersArea = (val) => {
    const areaLabel = dataAreas
      .filter((item) => val.indexOf(item.value) !== -1)
      .map((item) => item.value);

    setFilters({
      ...filters,
      area: areaLabel,
    });
  };

  const filtersTrl = (val) => {
    if (val === null) {
      setFilters({
        ...filters,
        trl: null,
      });
    } else {
      const trlLabel = dataTrl.filter((item) => item.value === val)[0].label;
      /**setFilters({
        ...filters,
        trl: Number(trlLabel),
      });*/
      setFilters({
        ...filters,
        trl: val,
      });
    }
  };

  const filtersStrategic = (val) => {
    setFilters({
      ...filters,
      only_strategic: val,
    });
  };

  const filtersExternal = (val) => {
    setFilters({
      ...filters,
      only_external: val,
    });
  };

  const filtersCategory = (val, slug) => {
    setFilters({
      ...filters,
      category: {
        ...filters.category,
        [slug]: val,
      },
    });
  };

  const filtersDateRange = (val) => {
    let rangeDate = [];
    if (val) {
      rangeDate = val.map((date) => {
        return dayjs(date).format("YYYY-MM-DD");
      });
    }

    setFilters({
      ...filters,
      range: rangeDate,
    });
  };

  useEffect(() => {
    return () => {
      resetFilters();
    };
  }, []);

  useEffect(() => {
    getData();
  }, [reloadRadarChart]);

  useEffect(() => {
    const handleDragStart = (event) => {
      const { clientX, clientY } = event;

      setInitialPosition({ x: clientX, y: clientY });
      setIsDragging(true);
    };

    const handleDrag = (event) => {
      if (isDragging) {
        const { clientX, clientY } = event;

        const offsetX = clientX - initialPosition.x + position.x;
        const offsetY = clientY - initialPosition.y + position.y;

        setPosition({ x: offsetX, y: offsetY });
        setInitialPosition({ x: clientX, y: clientY });
      }
    };

    const handleDragEnd = () => {
      setIsDragging(false);
    };

    if (draggableRef.current) {
      draggableRef.current.addEventListener("mousedown", handleDragStart);
      draggableRef.current.addEventListener("mousemove", handleDrag);
      draggableRef.current.addEventListener("mouseup", handleDragEnd);
    }

    return () => {
      if (draggableRef.current) {
        draggableRef.current.removeEventListener("mousedown", handleDragStart);
        draggableRef.current.removeEventListener("mousemove", handleDrag);
        draggableRef.current.removeEventListener("mouseup", handleDragEnd);
      }
    };
  }, [draggableRef.current, isDragging, initialPosition, position]);

  return (
    <Row className="radar-chart">
      <Col className="radar-chart--chart-col" span={16}>
        <Slider
          className="radar-chart--chart-col--slider"
          max={40}
          min={0}
          step={1}
          onChange={onZoom}
          vertical={true}
        />
        <div
          ref={draggableRef}
          draggable={false}
          style={{
            position: "absolute",
            top: position.y,
            left: position.x,
            width: "100%",
          }}
        >
          <h2 className="radar-chart--chart-col--title">
            Desenvolvimentos tecnológicos: Floresta e Indústria
          </h2>
          <Chart
            height={`${60 * scale}vh`}
            padding="auto"
            data={dataChart
              .filter((item) => {
                if (filters.area.length === 0) {
                  return item;
                } else {
                  return filters.area.indexOf(item.area_uid) !== -1;
                }
              })
              .filter((item) => {
                if (filters.trl === null) {
                  return item;
                } else {
                  return item.trl_uid === filters.trl;
                }
              })
              .filter((item) => {
                if (filters.only_strategic) {
                  return item.posts.findIndex((post) => post.strategic) !== -1;
                } else {
                  return item;
                }
              })
              .filter((item) => {
                if (filters.only_external) {
                  return (
                    item.posts.findIndex((post) => post.is_external) !== -1
                  );
                } else {
                  return item;
                }
              })
              .filter((item) => {
                const obj = filters.category[item.category_uid];
                if (obj && item.rank >= obj[0] && item.rank <= obj[1]) {
                  return item;
                } else if (!obj) {
                  return item;
                }
              })
              .filter((item) => {
                if (filters["range"].length > 0) {
                  return (
                    item.posts.findIndex((post) => {
                      let date = new Date(post.date);
                      date = new Date(date.setDate(date.getDate() + 1));
                      date = new Date(
                        date.getFullYear(),
                        date.getMonth(),
                        date.getDate()
                      );

                      let startDate = new Date(filters["range"][0]);
                      startDate = new Date(
                        startDate.getFullYear(),
                        startDate.getMonth(),
                        startDate.getDate()
                      );

                      let endDate = new Date(filters["range"][1]);
                      endDate = new Date(
                        endDate.getFullYear(),
                        endDate.getMonth(),
                        endDate.getDate()
                      );

                      if (date >= startDate && date <= endDate) {
                        return true;
                      } else {
                        return false;
                      }
                    }) !== -1
                  );
                } else {
                  return item;
                }
              })}
            autoFit
            scale={{
              trl_value: {
                min: 1,
                max: 9,
                ticks: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                tickInterval: 1,
                tickCount: 10,
              },
              area_value: {
                min: 0,
                max: dataAreas.length,
                ticks: dataAreas.map((item, index) => index).sort(),
                tickInterval: 1,
                tickCount: dataAreas.length,
              },
            }}
          >
            <Coordinate type="polar" innerRadius={0.2} />
            <Legend
              slidable={false}
              filter={false}
              rail={{
                size: 0,
              }}
              label=""
            />
            <Axis
              name="area_value"
              subTickLine={null}
              label={{
                formatter: (text, item, index) => {
                  return "";
                },
              }}
              verticalFactor={true}
              grid={{
                line: {
                  style: {
                    lineDash: [5, 2],
                    lineWidth: 1,
                  },
                },
              }}
            />
            <Tooltip
              shared={false}
              visible={true}
              triggerOn="click"
              enterable={true}
            >
              {(title, items) => {
                const data = items[0].data;
                const color = items[0].color;

                return (
                  <div className="radar-chart--chart-col--tooltip">
                    <p>
                      Área: <span>{data.area}</span>
                    </p>
                    <p>
                      Categoria: <span>{data.category}</span>
                    </p>
                    <p>
                      Avaliação da categoria: <span>{data.rank}</span>
                    </p>
                    <p>
                      Prontidão tecnológica vs Tempo para o mercado:{" "}
                      <span>{data.trl}</span>
                    </p>
                    <hr />
                    {data.posts
                      .filter((post) => {
                        if (filters.only_strategic) {
                          return post.strategic;
                        } else {
                          return post;
                        }
                      })
                      .filter((post) => {
                        if (filters.only_external) {
                          return post.is_external;
                        } else {
                          return post;
                        }
                      })
                      .map((post) => {
                        if (
                          (filters.only_strategic && post.strategic) ||
                          !filters.only_strategic
                        ) {
                          return (
                            <a
                              href={post.link}
                              onMouseDown={(e) => {
                                window.location.href = post.link;
                              }}
                            >
                              {post.title}
                            </a>
                          );
                        }
                      })}
                  </div>
                );
              }}
            </Tooltip>
            <Axis
              name="trl_value"
              line={null}
              subTickLine={null}
              tickLine={null}
              position="bottom"
              grid={{
                type: "circle",
                alternateColor: ["rgba(0, 0, 0, 0.2)"],
              }}
              label={{
                formatter: (text) => {
                  if (text !== "0" && text !== "10") {
                    return text;
                  } else {
                    return "";
                  }
                },
                offsetY: -8,
              }}
            />
            {!isDragging &&
              dataAreas.map((item, index) => {
                return (
                  <Annotation.Html
                    position={[index + 0.5, 10]}
                    html={`<span style="color: #555; font-size: 12px;" title="${
                      item.label
                    }">${
                      item.label.length > 9
                        ? item.label.slice(0, 9) + "..."
                        : item.label
                    }</span>`}
                    // content={() => <span>${item.label}</span>}
                    maxLength={64}
                    offsetX={item.offsetX}
                  />
                );
              })}
            {!isDragging && (
              <Annotation.Text
                position={[0, 0]}
                offsetX={-10}
                offsetY={((window.innerHeight * 0.6) / 18) * scale}
                content="TRL"
              />
            )}
            <Point
              color="category"
              position="area_value*trl_value"
              size={[
                "rank",
                (val) => {
                  return val + 4;
                },
              ]}
              shape="circle"
            />
          </Chart>
        </div>
      </Col>
      <Col span={8} className="radar-chart--filters-col">
        <div className="radar-chart--filters-col--filters">
          <span className="radar-chart--filters-col--filters--title">
            Filtros
            <hr />
          </span>
          <Form
            layout="vertical"
            className="radar-chart--filters-col--filters--form"
            // form={form}
            // initialValues={defaultFormValues}
            // onValuesChange={handleFormChange}
          >
            <Form.Item
              name="area"
              label="Áreas"
              className="radar-chart--filters-col--filters--form--item"
            >
              <Select
                onChange={filtersArea}
                popupClassName="radar-chart--filters-col--filters--form--item--select--dropdown"
                options={dataAreas}
                mode="multiple"
                placeholder="Todas"
                allowClear={true}
              />
            </Form.Item>
            {dataCategories.map((category) => (
              <Form.Item
                name={["category", category.slug]}
                // label={category.label}
                className="create-post--form-item select-category"
                labelCol={{ span: 24 }}
              >
                <Row gutter={[12, 12]} align="middle">
                  <Col span={12}>
                    <label>
                      {category.label}{" "}
                      {
                        <Popover
                          placement="bottom"
                          content={
                            <div>
                              <p>1 – Baixo</p>
                              <p>2 – Suficiente</p>
                              <p>3 – Bom</p>
                              <p>4 – Muito bom</p>
                              <p>5 – Forte</p>
                            </div>
                          }
                        >
                          <InfoCircleOutlined style={{ fontSize: 14 }} />
                        </Popover>
                      }
                    </label>
                  </Col>
                  <Col span={12}>
                    <Slider
                      range={true}
                      defaultValue={[1, 5]}
                      max={5}
                      min={1}
                      onChange={(val) => filtersCategory(val, category.value)}
                    />
                  </Col>
                </Row>
              </Form.Item>
            ))}
            <Form.Item
              name="range"
              label="Filtrar por período"
              className="radar-chart--filters-col--filters--form--item"
            >
              <DatePicker.RangePicker
                popupClassName="date-range-picker"
                placeholder={["aaaa-mm-dd", "aaaa-mm-dd"]}
                onChange={filtersDateRange}
              />
            </Form.Item>
            <Form.Item
              name="trl"
              label="Prontidão tecnológica vs Tempo para o mercado"
              initialValue={null}
              className="radar-chart--filters-col--filters--form--item"
            >
              <Select
                onChange={filtersTrl}
                popupClassName="radar-chart--filters-col--filters--form--item--select--dropdown"
                options={dataTrl}
              />
            </Form.Item>
            {/* <Form.Item
              label="Data de publicação"
              name="date"
              className="radar-chart--filters-col--filters--form--item"
            >
              <Tree
              // treeData={treeDate}
              // expandedKeys={treeExpandedKeys}
              // switcherIcon={<DownOutlined />}
              // onExpand={onExpand}
              // onSelect={onTreeSelect}
              />
            </Form.Item> */}
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default RadarChart;
