import React from "react";
import { Button, Modal} from "antd";

import "./index.less";

import ExternalArticlesTab from "./ExternalArticlesTab";


const ExternalArticlesModal = ({ show, onClose }) => {
 
  return (
    <Modal
      className="modal-external-articles"
      title="Gerir grupo externo"
      open={show}
      onCancel={onClose}
      destroyOnClose={true}
      footer={
        <Button onClick={onClose} type="link">
          Fechar
        </Button>
      }
    >
    <ExternalArticlesTab />
    </Modal>
  );
};

export default ExternalArticlesModal;