import React from "react";
import { Button, Modal} from "antd";

import "./index.less";

import StrategicGroupTab from "./StrategicGroupTab";


const StrategicGroupModal = ({ show, onClose }) => {
 
  return (
    <Modal
      className="modal-strategic-group"
      title="Gerir grupo estratégico"
      open={show}
      onCancel={onClose}
      destroyOnClose={true}
      footer={
        <Button onClick={onClose} type="link">
          Fechar
        </Button>
      }
    >
    <StrategicGroupTab />
    </Modal>
  );
};

export default StrategicGroupModal;
