import React, { useState, useEffect } from 'react';
import { Layout, Input, Row, Col, Dropdown, Button, AutoComplete } from 'antd';
import { GlobalOutlined, UserOutlined } from "@ant-design/icons";
import '@animated-burgers/burger-slip/dist/styles.css';
import classNames from 'classnames';
import {
  Link,
  useNavigate
} from "react-router-dom";
import Cluar from '../../common/Cluar';
import AreasCategoriesModal from "../../components/AreasCategoriesModal"
import StrategicGroupModal from "../../components/StrategicGroupModal"
import ExternalArticlesModal from "../../components/ExternalArticlesModal"
import LoginModal from "../../components/LoginModal"

import _service from "@netuno/service-client";
import _auth from '@netuno/auth-client';
import './index.less';

const { Header } = Layout;

function BaseHeader() {
  const navigate = useNavigate();

  const [showAreasCategoriesModal, setShowAreasCategoriesModal] = useState(false);
  const [showStrategicGroupModal, setShowStrategicGroupModal] = useState(false);
  const [showExternalArticlesModal, setShowExternalArticlesModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [burgerMenu, setBurgerMenu] = useState(false);
  const [activeMenu, setActiveMenu] = useState("main");

  const [autocompleteOptions, setAutocompleteOptions] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loadingSearch, setLoadingSearch] = useState(false);

  useEffect(() => {
    if (searchText !== "") {
      setLoadingSearch(true);

      getElasticSearch();
    } else {
      setAutocompleteOptions([]);
      setLoadingSearch(false);
    }
  }, [searchText]);

  const getElasticSearch = async () => {
    try {
      const values = await getPosts();

      setAutocompleteOptions(values ? searchResult(values) : []);
      setLoadingSearch(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const getPosts = () => {
    return new Promise((resolve, reject) => {
      _service({
        url: `/post/search?text=${searchText}`,
        method: 'GET',
        success: (response) => {
          const jsonData = response.json;

          if (jsonData.result) {
            const data = jsonData['data'];
            resolve(data);
          }

          resolve();
        },
        fail: (e) => {
          console.log("Service failed:", e);
          resolve();
        }
      });
    });
  }

  const searchResult = (values) => {
    return values.map((item, idx) => {
      const data = item['_source'];
      return {
        value: data.link,
        label: (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <span>
              <a
                //href={`${data.link}`}
                rel="noopener noreferrer"
                style={{ color: "#000" }}
              >
                {data.title}
              </a>
            </span>
          </div>
        ),
      };
    });
  }

  const handleMenuClick = (selectMenu) => {
    setBurgerMenu(false);
    if (selectMenu) {
      setActiveMenu(selectMenu);
    }
    window.scrollTo(0, 0);
  };

  const goToPostManagement = () => {
    navigate(`/gerir-artigos`, { replace: true });
  }

  const menuLanguages = {
    label: Cluar.currentLanguage().code,
    key: "langs",
    icon: <GlobalOutlined />,
    children: []
  };
  const menu = [];
  const subMenuKeys = [];
  const routes = [];
  for (const language of Cluar.languages()) {
    if (!Cluar.pages()[language.code]) {
      continue;
    }
    if (language.code !== Cluar.currentLanguage().code) {
      menuLanguages.children.push({
        key: language.code,
        label: (
          <div
            onClick={() => {
              Cluar.changeLanguage(language.locale);
              window.localStorage.setItem('locale', Cluar.currentLanguage().locale);
              window.location.href = `/${language.locale}/`;
            }}
          >
            {language.description}
          </div>
        )
      });
    }
    const buildChildren = (page) => {
      const children = Cluar.pages()[language.code]
        .filter((p) => p.parent === page.link);

      if (children.length === 0) {
        return;
      }

      return children.map((p) => {
        const key = p.link;

        subMenuKeys.push(key);

        if (p.menu) {
          return {
            key,
            label: p.navigable ? (
              p.link.indexOf('//') >= 0 ? (
                <a href={`${p.link}`} target="_blank">{p.title}</a>
              ) : (
                <Link to={`/${Cluar.currentLanguage().locale}${p.link}`} onClick={() => handleMenuClick(key)}>
                  {p.title}
                </Link>
              )
            ) : (
              <a>{p.title}</a>
            ),
            children: buildChildren(p)
          }
        }
      });
    }
    const buildMenu = (page) => {
      if (page.menu && language.code === Cluar.currentLanguage().code) {
        const key = `${page.link}`;

        subMenuKeys.push(key);

        if (!page.parent && page.menu) {
          return ({
            label: page.navigable ? (
              page.link.indexOf('//') >= 0 ? (
                <a href={`${page.link}`} target="_blank">{page.title}</a>
              ) : (
                <Link to={`/${Cluar.currentLanguage().locale}${page.link}`} onClick={() => handleMenuClick(key)}>
                  {page.title}
                </Link>
              )
            ) : (
              <a>{page.title}</a>
            ),
            key,
            children: buildChildren(page)
          });
        }
      }
      return;
    };

    const subroutes = [];
    for (const page of Cluar.pages()[language.code]) {
      if (page.menu && page.parent === "" && language.code === Cluar.currentLanguage().code) {
        menu.push(
          buildMenu(page, 0)
        );
      }
    }
  }

  const handleLogout = () => {
    _auth.logout();
    window.location.reload();
  }

  const items = [
    /**{
      key: "1",
      label: (
        <a target="_blank" href="#">
          Gerir utilizadores
        </a>
      ),
    },*/
    {
      key: "1",
      label: (
        <a target="_blank" onClick={() => goToPostManagement()}>
          Gerir artigos
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a href={null} onClick={() => setShowAreasCategoriesModal(true)}>
          Gerir áreas e categorias
        </a>
      ),
    },
    {
      key: "3",
      label: (
        <a href={null} onClick={() => setShowStrategicGroupModal(true)}>
          Gerir grupo estratégico
        </a>
      ),
    },
    {
      key: "4",
      label: (
        <a href={null} onClick={() => setShowExternalArticlesModal(true)}>
          Gerir grupo externo
        </a>
      ),
    },
    {
      key: "5",
      label: (
        <a style={{ color: "red" }} onClick={() => handleLogout()}>
          Sair
        </a>
      ),
    },
  ];

  const selectAutoCompletePost = (url) => {
    setSearchText("");
    navigate(url, { replace: true });
  }

  return (
    <Header className={classNames({ "header-burger-open": burgerMenu })}>
      <Row
        className="ant-layout-header__wrapper"
        align="middle"
        justify="space-between"
      >
        <Col className="ant-layout-header__wrapper__col">
          <Link to="/">
            <img alt="logo" src="/images/radartech.PNG" />
          </Link>
        </Col>
        <Col className="ant-layout-header__wrapper__col">
          {
            _auth.isLogged() ?
              (
                <Button type="primary" className='ant-layout-header__primary-button'>
                  <Link to={`${Cluar.getCurrentLanguageLink()}/criar-artigo`}>
                    Criar Artigo
                  </Link>
                </Button>
              ) : null
          }

          <AutoComplete
            options={autocompleteOptions}
            className="ant-layout-header__wrapper__col__search"
            style={{ '--antd-wave-shadow-color': 'transparent', width: 300 }}
            onSelect={(url) => selectAutoCompletePost(url)}
            onSearch={(text) => setSearchText(text)}
            value={searchText}
            dropdownMatchSelectWidth={252}
          >
            <Input.Search
              placeholder="Procurar artigos"
              enterButton
              allowClear
              loading={loadingSearch}
            />
          </AutoComplete>
          {
            _auth.isLogged() ?
              (
                <Dropdown
                  placement='bottomRight'
                  menu={{
                    items,
                  }}
                >
                  <Button type="link" style={{ padding: 0 }}>
                    <UserOutlined style={{ color: "#2d2d2d" }} />
                  </Button>
                </Dropdown>
              ) :
              (
                <Button onClick={() => setShowLoginModal(true)} type="link" style={{ padding: 0 }}>
                  <UserOutlined style={{ color: "#2d2d2d" }} />
                </Button>
              )
          }
        </Col>
        <AreasCategoriesModal
          show={showAreasCategoriesModal}
          onClose={() => setShowAreasCategoriesModal(false)}
        />
        <StrategicGroupModal
          show={showStrategicGroupModal}
          onClose={() => setShowStrategicGroupModal(false)}
        />
        <ExternalArticlesModal
          show={showExternalArticlesModal}
          onClose={() => setShowExternalArticlesModal(false)}
        />
        <LoginModal
          show={showLoginModal}
          onClose={() => setShowLoginModal(false)}
        />
      </Row>
    </Header>
  );
}

export default BaseHeader;