import React, { useState, useEffect } from 'react';
import { useQuill } from 'react-quilljs';
import ResizeModule from "@botom/quill-resize-module";

import ImageUploader from 'quill-image-uploader';

import "./index.less";
import 'react-quill/dist/quill.snow.css';

const ContentQuill = (props) => {
    useEffect(() => {
        if (props.defaultContent !== "") {
            const htmlContent = props.defaultContent;
            const delta = quill.clipboard.convert(htmlContent);
            quill.setContents(delta, 'silent')
        }
    }, [props.defaultContent]);

    const { quill, quillRef, Quill } = useQuill({
        modules: {
            resize: {
                toolbar: {
                    alingTools: true,
                },
                showSize: true,
                sizeTools: false,
                locale: {},
            },
            // imageUploader: {
            //     upload: (file) => {

            //         console.log("modules??")
            //         return new Promise((resolve, reject) => {
            //             const formData = new FormData();
            //             formData.append('image', file);

            //             fetch('/api/upload-image', {
            //                 method: 'POST',
            //                 body: formData,
            //             })
            //                 .then((response) => response.json())
            //                 .then((data) => {
            //                     resolve(data.url);
            //                 })
            //                 .catch((error) => {
            //                     reject('Upload failed');
            //                 });
            //         });
            //     },
            // },
        },
        formats: [
            'image',
            'width',
            'align',
            'display',
            'margin',
            'size',
            'style',
            'bold',
            'italic',
            'underline',
            'strike',
            'list',
            'indent',
            'header',
            'link',
            'color',
            'background',
            'clear'
        ],
        imageUploader: {
            upload: (file) => {
                return new Promise((resolve, reject) => {
                    console.log("UPLOAD IMAGE XHR")
                    const xhr = new XMLHttpRequest();
                    xhr.open('POST', '/api/upload-image');
                    const formData = new FormData();
                    formData.append('image', file);
                    xhr.onload = () => {
                        if (xhr.status === 200) {
                            resolve(xhr.responseText);
                        } else {
                            reject('Upload failed');
                        }
                    };
                    xhr.send(formData);
                });
            },
        },
    });

    if (Quill && !quill) {
        Quill.register("modules/resize", ResizeModule);

        const Parchment = Quill.import('parchment');
        const BaseImage = Quill.import('formats/image');

        const ATTRIBUTES = ['alt', 'height', 'width', 'style'];

        const WHITE_STYLE = ['margin', 'display', 'float'];

        class Image extends BaseImage {
            static formats(domNode) {
                return ATTRIBUTES.reduce((formats, attribute) => {
                    if (domNode.hasAttribute(attribute)) {
                        formats[attribute] = domNode.getAttribute(attribute);
                    }
                    return formats;
                }, {});
            }

            format(name, value) {
                if (ATTRIBUTES.indexOf(name) > -1) {
                    if (value) {
                        if (name === 'style') {
                            value = this.sanitize_style(value);
                        }
                        this.domNode.setAttribute(name, value);
                    } else {
                        this.domNode.removeAttribute(name);
                    }
                } else {
                    super.format(name, value);
                }
            }

            sanitize_style = (style) => {
                const style_arr = style.split(';');
                let allow_style = '';
                style_arr.forEach((v) => {
                    if (WHITE_STYLE.indexOf(v.trim().split(':')[0]) !== -1) {
                        allow_style += `${v};`;
                    }
                });
                return allow_style;
            };
        }

        Quill.register(Image, true);
        Quill.register(new Parchment.Attributor.Style('display', 'display', {
            whitelist: ['inline']
        }));
        Quill.register(new Parchment.Attributor.Style('float', 'float', {
            whitelist: ['left', 'right', 'center']
        }));
        Quill.register(new Parchment.Attributor.Style('margin', 'margin', {}));

        Quill.register("modules/imageUploader", ImageUploader);
    }

    useEffect(() => {
        if (quill) {
            quill.on('text-change', (delta, oldContents) => {
                const htmlContent = quill.root.innerHTML;
                props.onChange(htmlContent);
            });
        }
    }, [quill, Quill]);

    return (
        <div className={"editor"}>
            <div ref={quillRef} />
        </div>
    );
};

export default ContentQuill;