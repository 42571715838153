import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Button, Spin } from "antd";
import { ArrowDownOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

import _service from "@netuno/service-client";
import PostCard from "./PostCard";

import { DataContext } from "../../../App";

import "./index.less";

const PostList = ({ isSearch, searchString }) => {
  const { filters, setFilters } = useContext(DataContext);
  console.log("searchString", searchString)

  const defaultPagination = {
    current: 1,
    pageSize: 8,
    total: 0,
  };

  const [posts, setPosts] = useState([]);
  const [pagination, setPagination] = useState(defaultPagination);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);

  useEffect(() => {
    if (isSearch) {
      setFilters({
        ...filters,
        searchString,
      });
    }
  }, [searchString]);

  useEffect(() => {
    console.log("Filters changed!!");
    console.log(filters);
    console.log(pagination);

    setLoading(true);
    getPosts(true);
  }, [filters]);

  const getPosts = (reset = false) => {
    _service({
      url: "/post/list",
      method: 'POST',
      data: {
        filters: filters,
        pagination: reset ? {
          current: 1,
          pageSize: 8,
          total: 0,
        } : pagination
      },
      success: (response) => {
        const jsonData = response.json;

        if (jsonData.result) {
          const data = jsonData['data'];
          console.log(data);

          if (!reset) {
            setPosts((prevArray) => [...prevArray, ...data]);
          } else {
            setPosts(data);
          }

          setPagination({
            ...pagination,
            total: jsonData.total,
          });

          setLoading(false);
          setLoadingMore(false);
        }
      },
      fail: (e) => {
        console.log("Service failed:", e);
      }
    });
  };

  const onLoadMore = () => {
    setLoadingMore(true);

    console.log("onLoadMore");

    addNewPage().then(() => {
      getPosts();
    });
  };

  const addNewPage = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        setPagination({
          ...pagination,
          current: (pagination.current += 1),
        });
        resolve();
      }, 250);
    });
  }

  const handleFilters = (newFilters) => {
    let newRange = [];
    if (newFilters["range"]) {
      newRange = newFilters["range"].map((date) => {
        return dayjs(date).format("YYYY-MM-DD");
      });
    }

    newFilters = { ...newFilters, range: newRange };
    setFilters({ ...filters, ...newFilters });
    setPagination({
      ...pagination,
      current: 1,
    });
  };

  return (
    <Row className="post-list" justify="center">
      <Col span={24} className="post-list--col-left">
        {isSearch ? (
          <span className="post-list--title">
            Pesquisa: {filters.searchString}
          </span>
        ) : (
          <span className="post-list--title">Artigos</span>
        )}
        {loading ? (
          <Row gutter={[24, 24]} className="post-list--list">
            <Spin style={{ margin: "auto" }} className="post-list--list--spin" size="large" />
          </Row>
        ) : posts.length > 0 ? (
          <Row gutter={[24, 24]} className="post-list--list">
            {posts.map((post, index) => (
              <Col
                className="post-list--list--col"
                key={`${post.uid}-${index}`}
              >
                <PostCard data={post} />
              </Col>
            ))}
          </Row>
        ) : posts.length === 0 ? (
          <span className="post-list--not-found">
            Não foi encontrado nenhum post.
          </span>
        ) : loading ? (
          <Row gutter={[24, 24]} className="post-list--list">
            <Spin style={{ margin: "auto" }} />
          </Row>
        ) : null}

        <Row className="post-list--load-more">
          {loadingMore ? (
            <Spin style={{ margin: "auto" }} />
          ) : (pagination.current * pagination.pageSize) < pagination.total ? (
            <Button
              className="post-list--load-more--btn"
              onClick={() => onLoadMore()}
            >
              <ArrowDownOutlined />
              Carregar mais artigos
            </Button>
          ) : null}
        </Row>
      </Col>
    </Row>
  );
};

export default PostList;
