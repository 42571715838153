import React from "react";

import { Link, useNavigate } from "react-router-dom";
import { Button, Typography } from "antd";
import { FilePdfOutlined, EditOutlined } from "@ant-design/icons";

import _auth from "@netuno/auth-client"

import PDFExport from "../../../PDFExport";

import "./index.less";

const PostCard = (props) => {
  const navigate = useNavigate();
  const exportPDFRef = React.useRef();
  
  const data = props.data;
  const post = props.data;
  const postMetadata = post['metadata'] ? post['metadata'] : [];
  const categories = post.category;
  const trl = postMetadata.trl_value;
  const area = postMetadata.area_label;

  return (
    <Link style={{ textDecoration: "none" }} to={data.link}>

      <div className="post-card">
        <div
          className="post-card--image"
          style={{
            backgroundImage: `url(${data.featured_image ? data.featured_image : "/images/capa.png"})`,
          }}
        >
          {data['metadata'].strategic ? 
            ( <span className="post-card--tag">Estratégico</span> )
            : null
          }
        </div>
        <div className="post-card--body">
          <div>
            <span className="post-card--body--data">Em {data.date}</span>
            <h2 className="post-card--body--title">
              {data.title}
            </h2>
            <Typography.Paragraph ellipsis={{rows: 5}} className="post-card--body--description">
              {data.description}
            </Typography.Paragraph>
          </div>
          <div>
            <hr />
            <div className="post-card--body--buttons">
              <Button className="post-card--body--buttons--pdf" onClick={(e) => {
                e.preventDefault();
                exportPDFRef.current?.printPDF(post);
              }}>
                <FilePdfOutlined />
                Exportar artigo em PDF
              </Button>
              {_auth.isLogged() ? (
                <Button className="post-card--body--buttons--edit" onClick={(e) => {
                  e.preventDefault();
                  navigate(`/editar-artigo`, { params: { uid: data.uid }, state: { uid: data.uid } });
                }}>
                  <EditOutlined style={{color: "#fff"}} />
                </Button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default PostCard;
