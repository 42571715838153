import React, { useState, useEffect } from "react";
import dayjs from 'dayjs';
import { Table, Typography, Switch, Button, Popconfirm, notification } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined,
  FastForwardFilled,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import _service from "@netuno/service-client";

import Cluar from "../../../common/Cluar";
import FiltersManagement from "./FiltersManagement";

import "./index.less";

const PostsManagement = () => {
  const navigate = useNavigate();

  const defaultFilters = {
    order: "latest",
    only_strategic: false,
    only_external: false,
    area: null,
    trl: null,
    category: {},
    range: [],
    search_string: ""
  }

  const defaultPagination = {
    current: 1,
    pageSize: 10,
    total: 0,
  };

  const [filters, setFilters] = useState(defaultFilters);
  const [areas, setAreas] = useState([]);
  const [categories, setCategories] = useState([]);

  const [posts, setPosts] = useState([]);
  const [pagination, setPagination] = useState(defaultPagination);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log("Filters changed!!");
    console.log(filters);
    console.log(pagination);

    setLoading(true);
    getPosts(true);
  }, [filters]);

  const getPosts = (reset = false, page) => {
    if (reset) {
      page = 1;
    }

    _service({
      url: "/post/list",
      method: 'POST',
      data: {
        filters: filters,
        pagination: reset ? {
          current: 1,
          pageSize: 10,
          total: 0,
        } : {
          ...pagination,
          current: page ? page : pagination.current
        }
      },
      success: (response) => {
        const jsonData = response.json;

        if (jsonData.result) {
          const data = jsonData['data'];
          setPosts(data);

          setPagination({
            ...pagination,
            current: page ? page : pagination.current,
            total: jsonData.total,
          });
          window.scrollTo(0, 200);
          setLoading(false);
        }
      },
      fail: (e) => {
        console.log("Service failed:", e);
      }
    });
  };

  const handlePageChange = (page) => {
    setLoading(true);
    getPosts(false, page);
  }

  const changeStrategicValue = (record) => {
    setLoading(true);
    _service({
      url: "/post/change-strategic",
      method: 'POST',
      data: {
        uid: record.uid,
      },
      success: (response) => {
        const data = response.json;

        if (data.result) {
          notification.success({
            description: 'O estado de estratégico foi atualizado com sucesso.'
          });

          getPosts(false, pagination.current);
        } else {
          notification.error({
            description: 'Não foi possível atualizar o estado de estratégico.'
          });
        }

        setLoading(false);
      },
      fail: (e) => {
        setLoading(false);
        notification.error({
          description: 'Não foi possível atualizar o estado de estratégico.'
        });
        console.log("Service failed:", e);
      }
    });
  }
  const changeExternalValue = (record) => {
    setLoading(true);
    _service({
      url: "/post/change-external",
      method: 'POST',
      data: {
        uid: record.uid,
      },
      success: (response) => {
        const data = response.json;

        if (data.result) {
          notification.success({
            description: 'O estado de externo foi atualizado com sucesso.'
          });

          getPosts(false, pagination.current);
        } else {
          notification.error({
            description: 'Não foi possível atualizar o estado de externo.'
          });
        }

        setLoading(false);
      },
      fail: (e) => {
        setLoading(false);
        notification.error({
          description: 'Não foi possível atualizar o estado de externo.'
        });
        console.log("Service failed:", e);
      }
    });
  }
  const deletePost = (record) => {
    setLoading(true);
    _service({
      url: "/post",
      method: 'DELETE',
      data: {
        uid: record.uid,
      },
      success: (response) => {
        const data = response.json;

        if (data.result) {
          notification.success({
            description: 'O artigo foi apagado com sucesso.'
          });

          getPosts();
        } else {
          notification.error({
            description: 'Não foi possível apagar o artigo.'
          });
        }

        setLoading(false);
      },
      fail: (e) => {
        setLoading(false);
        notification.error({
          description: 'Não foi possível apagar o artigo.'
        });
        console.log("Service failed:", e);
      }
    });
  }

  const columns = [
    {
      title: "Título",
      dataIndex: "title",
      key: "title",
      width: "20%",
      render: (_, row) => (
        <Typography.Paragraph
          className="posts-management--table--paragraph"
          ellipsis={{ rows: 3 }}
        >
          {row.title}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Descrição",
      dataIndex: "description",
      key: "description",
      width: "30%",
      render: (_, row) => (
        <Typography.Paragraph
          className="posts-management--table--paragraph"
          ellipsis={{ rows: 3 }}
        >
          {row.description}
        </Typography.Paragraph>
      ),
    },
    // {
    //   title: "Categoria",
    //   dataIndex: "categories",
    //   key: "categories",
    //   render: (_, row) => (
    //     <Typography.Paragraph
    //       className="posts-management--table--paragraph"
    //       ellipsis={{ rows: 3 }}
    //     >
    //       {row.categories_label ? row.categories_label.join(",\n") : ""}
    //     </Typography.Paragraph>
    //   ),
    // },
    {
      title: "Área",
      dataIndex: "area",
      key: "area",
      width: "10%",
      render: (_, row) => (
        <Typography.Paragraph
          className="posts-management--table--paragraph posts-management--table--paragraph-center"
          ellipsis={{ rows: 3 }}
        >
          {row.area ? row.area : ""}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Estratégico",
      dataIndex: "strategic",
      key: "strategic",
      render: (_, row) => (
        <Switch
          defaultChecked={row['metadata'].strategic}
          checked={row['metadata'].strategic}
          className="posts-management--table--switch"
          onClick={() => changeStrategicValue(row)}
        />
      ),
    },
    {
      title: "Externo",
      dataIndex: "is_external",
      key: "is_external",
      render: (_, row) => (
        <Switch
          defaultChecked={row['metadata'].is_external}
          checked={row['metadata'].is_external}
          className="posts-management--table--switch"
          onClick={() => changeExternalValue(row)}
        />
      ),
    },
    {
      title: "Ações",
      dataIndex: "actions",
      key: "actions",
      render: (_, row) => (
        <div className="posts-management--table--actions">
          <Button className="edit-area-btn" type="link" onClick={(e) => {
            e.preventDefault();
            navigate('/editar-artigo', { params: { uid: row.uid }, state: { uid: row.uid } });
          }}>
            <EditOutlined style={{ fontSize: 20, color: "#2D2D2D" }} />
          </Button>
          <Popconfirm
            placement="top"
            title="O artigo será eliminado da plataforma. Deseja eliminar a publicação existente?"
            onConfirm={() => deletePost(row)}
            okText="Sim"
            cancelText="Não"
            okType="link"
            okButtonProps={{
              className: "pop-confirm-confirm-btn",
            }}
            cancelButtonProps={{
              className: "pop-confirm-cancel-btn",
            }}
            icon={<WarningOutlined style={{ color: "#E70000" }} />}
            rootClassName="pop-confirm"
          >
            <Button className="delete-area-btn" type="link">
              <DeleteOutlined
                style={{ fontSize: 20, color: "rgba(255, 0, 0, 0.6)" }}
              />
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const handleAreaCategories = (data) => {
    setAreas(data['areaFilters']);
    setCategories(data['categoryFilters']);
  }

  const handleFilters = (newFilters) => {
    let newRange = [];
    if (newFilters['range']) {
      newRange = newFilters['range'].map(date => {
        return dayjs(date).format('YYYY-MM-DD');
      });
    }

    console.log("categories", categories);
    console.log("newRange", newRange);
    console.log("newFilters", newFilters);

    newFilters = { ...newFilters, range: newRange };
    setFilters({ ...filters, ...newFilters });
  }

  return (
    <div className="posts-management">
      <span className="posts-management--title">Gestão de Artigos</span>
      <FiltersManagement onChange={handleFilters} handleAreaCategories={handleAreaCategories} />
      <Table
        className="posts-management--table"
        columns={columns}
        dataSource={posts}
        pagination={{
          ...pagination,
          onChange: (e) => handlePageChange(e), // Handle page change event
        }}
        loading={loading}
      />
    </div>
  );
};

export default PostsManagement;
