import React, {useState} from "react"

import {Button, Modal} from "antd"

import "./index.less";

const TermsConditionsButton = () => {
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false)

  return (
    <>
      <Button style={{padding: 0}} type='Link' onClick={() => setIsTermsModalOpen(true)}>Termos e Condições</Button>
      <Modal title="Termos e Condições" open={isTermsModalOpen} onCancel={() => setIsTermsModalOpen(false)} footer={null} className="terms-conditions-modal">
      <ol>
        <li>
          Disposições Gerais e Descrição do Serviço
          <ol>
            <li>Os presentes “Termos e Condições” regulam o acesso e utilização da plataforma RADARtech, Radar de Bioeoconomia de Base Florestal e Futuros (doravante, “Plataforma”) cuja propriedade pertence ao Instituto da Floresta e Papel (doravante “RAIZ”).</li>
            <li>A navegação na Plataforma, bem como o recurso aos serviços disponibilizados nesta, implicam a aceitação dos presentes Termos e Condições pelo Utilizador.</li>
            <li>A presente Plataforma foi criada, baseada na patente do RAIZ, financiada pelo INOV C+, e será disponibilizada ao consórcio divulgação e avaliação da informação recolhida no âmbito da atividade de Vigilância Tecnológica do RAIZ, que acompanha e reporta tendências/novidades de processos e tecnologias e de investigação e desenvolvimento dos sectores Florestal, da Pasta & Papel, da Biorrefinaria e da Bioeconomia Florestal, para comunicação à entidade financiadora.</li>
            <li>O RAIZ é ainda a responsável pela administração Plataforma, incluindo o controlo de acessos e a gestão de conteúdos.</li>
            <li>O RAIZ reserva-se o direito de, a todo o momento, sem aviso e com efeitos imediatos, alterar, acrescentar ou revogar, parcial ou totalmente, os presentes Termos e Condições. Quaisquer alterações serão imediatamente divulgadas, constituindo um dever do utilizador consultá-las periodicamente.</li>
          </ol>
        </li>

        <li>
          Condições de Utilização
          <ol>
            <li>Este documento estabelece os termos, condições e regras a que fica sujeito o acesso e utilização da Plataforma, assim como de qualquer conteúdo apresentado ou disponibilizado (“Condições de Utilização”).</li>
            <li>As presentes Condições de Utilização não prejudicam a aplicação das regras legais relevantes, nem de outras condições que sejam definidas pelo RAIZ para acesso e/ou utilização de funcionalidades, serviços ou conteúdos específicos.</li>
            <li>Independentemente de qualquer aceitação anterior, as Condições de Utilização, sempre na sua versão mais recente, são automática e integralmente aplicáveis a qualquer utilizador que aceda à Plataforma, sendo irrelevante o objetivo – [divulgação e avaliação da informação recolhida no âmbito da atividade de Vigilância Tecnológica do RAIZ, que acompanha e reporta tendências/novidades de processos e tecnologias e de investigação e desenvolvimento dos sectores Florestal, da Pasta & Papel, da Biorrefinaria e da Bioeconomia Florestal, para comunicação à entidade financiadora] - que tenha motivado tal acesso.</li>
            <li>Dada a aplicação integral e automática, caso o utilizador não concorde, em qualquer momento, com qualquer uma das suas regras, termos ou condições, deverá cessar a utilização da Plataforma.</li>
            <li>A cessação imediata da utilização da Plataforma não prejudicará a aplicação destas Condições de Utilização e das demais regras legais ou contratuais à utilização que tiver sido feita pelo utilizador antes de tal momento.</li>
            <li>Em caso de dúvida acerca da interpretação e/ou aplicação destes Condições de Utilização, o utilizador deverá contactar os serviços do RAIZ através do endereço de correio eletrónico mariana.oliveira@thenavigatorcompany.com</li>
          </ol>
        </li>

        <li>
          Acesso
          <ol>
            <li>O RAIZ reserva o direito exclusivo de, a qualquer momento e sem necessidade de aviso prévio, impedir, suspender ou condicionar o acesso à Plataforma ou a qualquer uma das funcionalidades, serviços ou conteúdos, a qualquer utilizador que não cumpra as Condições de Utilização ou as normas legais vigentes.</li>
            <li>Em virtude dos presentes Termos e Condições, os Utilizadores terão, através da Plataforma, acesso à informação descrita no ponto 1.3 deste documento.</li>
            <li>
              Para aceder à Plataforma pela primeira vez:
              <ol>
                <li>Utilizadores Internos – O acesso é realizado através de link disponível na Intranet, não sendo necessário efetuar fornecer dados para o acesso.</li>
                <li>Utilizadores Externos – Com base na informação recolhida através da Plataforma iMPACTO, Plataforma de Avaliação de Impacto, será enviado um link de demonstração, para visualização da plataforma, sem que seja necessário o registo na plataforma. </li>
              </ol>
            </li>
          </ol>
        </li>

        <li>
          Informações de Registo
          <ol>
            <li>O código de entrada na plataforma (password) tem carácter pessoal e intransmissível e é para conhecimento e uso exclusivo do utilizador, sendo este responsável por assegurar e proteger, em quaisquer circunstâncias, a confidencialidade do mesmo, evitando, em especial, a sua revelação a terceiros não autorizados e a sua utilização em condições que permitam a sua descodificação e cópia.</li>
            <li>Qualquer utilização da Plataforma com os dados de registo do utilizador (código de identificação pessoal e password), com ou sem a sua autorização, formal ou tácita, será imputável ao utilizador em causa, salvo se este puder demonstrar que não violou a obrigação de confidencialidade prevista na cláusula 4.1.</li>
            <li>O utilizador obriga-se a comunicar imediatamente ao RAIZ, para o endereço de correio eletrónico [mariana.oliveira@thenavigatorcompany.com] ou para o telefone número [234 920 130], toda e qualquer violação de segurança e/ou quebra ou risco de quebra de confidencialidade, para que o RAIZ possa tomar as devidas precauções.</li>
            <li>O RAIZ assegura a segurança e a confidencialidade dos dados registados para efeitos de utilização da Plataforma, mas não aceita qualquer responsabilidade por perdas ou danos causados por utilizações abusivas dos dados referidos nos números anteriores que não lhe sejam diretamente imputáveis a título de dolo ou culpa grave.</li>
          </ol>
        </li>

        <li>
          POLÍTICA DE PRIVACIDADE E DE COOKIES
          <ol>
            <li>
              O RAIZ respeita a privacidade do Utilizador e a proteção dos seus dados pessoais de acordo com o disposto no Regulamento Geral de Proteção de Dados (UE) 2016/679 do Parlamento Europeu e do Conselho, de 27 de abril de 2016, relativo à proteção das pessoas singulares, no que diz respeito ao tratamento de dados pessoais e à livre circulação destes dados (adiante “RGPD”) e Lei 58/2019 de 8 de agosto, que assegura a execução, na ordem jurídica nacional, do RGPD.<br/>
              Pode em qualquer momento consultar a política de privacidade e política de cookies através dos seguintes links: <a target="_blank" href="http://raiz-iifp.pt/politica-de-privacidade/">Política de Privacidade e Cookies</a>
            </li>
          </ol>
        </li>

        <li>
          PROPRIEDADE INTELECTUAL
          <ol>
            <li>Todos os textos, comentários, trabalhos, ilustrações, obras e imagens, software e código-fonte reproduzidos ou representados na Plataforma encontram-se devidamente protegidos pelo direito de autor e são propriedade do RAIZ, nos termos do Código do Direito de Autor e dos Direitos Conexos (CDADC), apenas será autorizada a sua utilização para fins do projeto INOV C+, sem prejuízo de disposições mais restritivas constantes do mencionado Código. Qualquer reprodução ou representação total ou parcial da Plataforma ou de todo ou parte dos elementos incluídos no mesmo é estritamente proibida, sob pena de recurso aos meios legais competentes.</li>
          </ol>
        </li>

        <li>
          COMUNICAÇÕES
          <ol>
            <li>Ao utilizar o Plataforma os Utilizadores aceitam expressamente que a comunicação com o RAIZ de forma eletrónica ou outro meio de comunicação à distância, satisfaz a exigência legal para comunicações sujeitas a forma escrita.</li>
            <li>As comunicações serão realizadas entre as partes através dos contactos constantes nos presentes Termos e Condições no momento da celebração do contrato.</li>
            <li>Qualquer comunicação será tida por recebida 24 (vinte e quatro) horas após o envio de uma mensagem escrita para contacto de endereço de email do utilizador. Para prova do envio de uma comunicação, basta provar que a mesma mensagem foi enviada.</li>
          </ol>
        </li>

        <li>
          CONFIDENCIALIDADE
          <ol>
            <li>
              Para efeitos dos presentes Termos e Condições, “Informação Confidencial” significa:<br/>
              – Quaisquer informações, de qualquer natureza, em qualquer suporte, ou dados com os conceitos, experiências, know-how, procedimentos técnicos e outros aspetos relacionados ou não com os serviços, revelados, no todo ou em parte, por escrito, oralmente ou por qualquer outra forma;<br/>
              – Qualquer material publicitário e/ou de comunicação, know-how, procedimentos técnicos.
            </li>
            <li>O RAIZ obriga-se a manter em segredo a Informação Confidencial e, bem assim, a adotar todas as medidas adequadas a esse fim, usando de todo o cuidado e zelo necessários a manter tal Informação Confidencial a salvo de revelação, cópia ou uso não autorizados, abstendo-se de reproduzir, imitar ou ceder a terceiro a Informação Confidencial a que tenham acesso.</li>
            <li>O RAIZ obriga-se ainda a assegurar que essa Informação Confidencial é restrita aos seus empregados, executivos, diretores e subcontratados que devam ter acesso à mesma em virtude da respetiva participação na prestação dos serviços e apenas e só com esse objetivo.</li>
            <li>As Partes obrigam-se a não divulgar a Informação Confidencial a qualquer terceiro, a não fazer qualquer aproveitamento comercial desta e a utilizá-la apenas no âmbito e nos termos dos presentes Termos e Condições.</li>
          </ol>
        </li>

        <li>
          Segurança
          <ol>
            <li>Qualquer alteração de conteúdos, de carregamento de informação, de acesso não autorizado, incluindo a tentativa de, ou qualquer outra ação que possa causar dano ou pôr em risco a integridade, segurança, funcionalidade ou utilização da Plataforma são proibidas e podem ser punidas pela legislação em vigor.</li>
            <li>Nos termos previstos na cláusula 3.1., o RAIZ terá o direito de tomar todas as providências necessárias para assegurar a integridade, segurança, funcionalidade e utilização da Plataforma ou para evitar perdas, utilização indevida ou alteração dos conteúdos ali disponibilizados.</li>
            <li>Por razões de segurança, não serão permitidos acessos à Plataforma com o mesmo endereço de correio eletrónico de utilizador e password.</li>
          </ol>
        </li>

        <li>
          RESPONSABILIDADE E EXCLUSÕES
          <ol>
            <li>O RAIZ não é responsável por eventuais prejuízos decorrentes de interferências, interrupções, vírus informáticos, avarias ou desconexões do sistema operativo que possam impedir temporariamente o acesso, a navegação ou a prestação de serviços ao Utilizador.</li>
            <li>O RAIZ realiza todos os esforços para assegurar que a informação da sua Plataforma se encontra exata e atualizada e para retificar quaisquer erros ou omissões logo que seja possível quando comunicados.</li>
            <li>O RAIZ não possui qualquer responsabilidade por perdas ou danos decorrentes da incapacidade de aceder à Plataforma.</li>
            <li>A utilização de qualquer website de terceiros é regulada pelos termos e condições e pela política de privacidade desse mesmo website, isentando o RAIZ de qualquer responsabilidade proveniente dessa utilização.</li>
          </ol>
        </li>

        <li>
          REDUÇÃO
          <p>A declaração de invalidade, ilegalidade ou ineficácia, por autoridade competente, de qualquer uma das disposições dos presentes Termos e Condições não afetará nenhuma das restantes disposições, que continuarão em pleno vigor.</p>
        </li>

        <li>
          LEGISLAÇÃO APLICÁVEL E RESOLUÇÃO ALTERNATIVA DE LITÍGIOS
          <ol>
            <li>Os contratos de prestação de serviços celebrados através da Plataforma são regulados pela Lei Portuguesa.</li>
            <li>Qualquer litígio resultante ou relacionado com esses contratos está sujeito à jurisdição exclusiva dos tribunais portugueses.</li>
          </ol>
        </li>

        <li>
          Disposições Finais
          <ol>
            <li>Todas as mensagens eletrónicas enviadas durante a utilização da Plataforma serão consideradas, para efeitos da lei aplicável, como declarações contratuais.</li>
            <li>Considera-se parte integrante das presentes Condições de Utilização a política de privacidade disponível na página inicial da Plataforma.</li>
          </ol>
        </li>
      </ol>
      </Modal>
    </>
  )
}

export default TermsConditionsButton