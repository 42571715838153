import React, { useState, useEffect } from "react";
import {
  Button,
  Table,
  Input,
  Row,
  Col,
  Form,
  Popconfirm,
  notification,
  Spin,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  WarningOutlined,
  CloseOutlined,
  SaveOutlined,
} from "@ant-design/icons";

import _service from "@netuno/service-client";

import "./index.less";

const EditableCell = ({
  editing,
  dataIndex,
  record,
  index,
  children,
  ...restProps
}) => {

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
            textAlign: "center",
          }}
          rules={[{ required: true, type: "email", message: "Insira um email válido" }]}
        >
          <Input style={{ textAlign: "center" }} />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const ExternalArticlesTab = () => {
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingAddData, setLoadingAddData] = useState(false);
  const [editingKey, setEditingKey] = useState("");
  const [formEdit] = Form.useForm();
  const [formAdd] = Form.useForm();

  useEffect(() => {
    getExternalGroupData();
  }, []);

  const createGroupEmail = ({ email }) => {
    setLoadingAddData(true);

    _service({
      url: "/external-post",
      method: 'POST',
      data: {
        email
      },
      success: (response) => {
        console.log("response", response);
        const data = response.json;

        if (data.result) {
          notification.success({
            description: 'O email foi adicionado com sucesso.'
          });

          formAdd.resetFields();
          getExternalGroupData();
        } else {
          notification.error({
            description: 'Não foi possível adicionar o email.'
          });
        }

        setLoadingAddData(false);
      },
      fail: (e) => {
        setLoadingAddData(false);
        console.log("Service failed:", e);

        if (e.status === 409) {
          notification.error({
            description: 'Este email já existe.'
          });
        } else {
          notification.error({
            description: 'Não foi possível adicionar o email.'
          });
        }
      }
    });
  }

  const getExternalGroupData = () => {
    setLoadingData(true);
    fetch(`/data/post-external.json?time=${new Date().getTime()}`)
      .then((response) => response.json())
      .then(({ data }) => {
        setData(data);
        setLoadingData(false);
      });
  }

  const updateGroupEmail = (record) => {
    formEdit.validateFields()
    .then(() => {
      const newValue = formEdit.getFieldValue("email");
      setLoadingData(true);
  
      if (newValue !== record.name) {
        _service({
          url: "/external-post",
          method: 'PUT',
          data: {
            uid: record.uid,
            email: newValue
          },
          success: (response) => {
            const data = response.json;
    
            if (data.result) {
              notification.success({
                description: 'O email foi atualizado com sucesso.'
              });
  
              setEditingKey("")
              getExternalGroupData();
            } else {
              notification.error({
                description: 'Não foi possível atualizar o email.'
              });
            }
  
            setLoadingData(false);
          },
          fail: (e) => {
            setLoadingData(false);
            console.log("Service failed:", e);
  
            if (e.status === 409) {
              notification.error({
                description: 'Este email já existe.'
              });
            } else {
              notification.error({
                description: 'Não foi possível atualizar o email.'
              });
            }
          }
        });
      }
    });
    
  }

  const deleteGroupEmail = (record) => {
    setLoadingData(true);

    _service({
      url: "/external-post",
      method: 'DELETE',
      data: {
        uid: record.uid,
      },
      success: (response) => {
        const data = response.json;

        if (data.result) {
          notification.success({
            description: 'O email foi apagado com sucesso.'
          });

          getExternalGroupData();
        } else {
          notification.error({
            description: 'Não foi possível apagar o email.'
          });
        }

        setLoadingData(false);
      },
      fail: (e) => {
        setLoadingData(false);
        notification.error({
          description: 'Não foi possível apagar o email.'
        });
        console.log("Service failed:", e);
      }
    });
  }

  const isEditing = (record) => record.uid === editingKey;

  const editCategory = (record) => {
    formEdit.setFieldsValue({
      email: record.email,
    });
    setEditingKey(record.uid);
  };

  const columns = [
    {
      title: "E-mails",
      dataIndex: "email",
      key: "email",
      editable: true,
    },
    {
      title: "Ações",
      key: "actions",
      render: (_, record) => {
        const editable = isEditing(record);
        return !editable ? (
          <div>
            <Button
              type="link"
              onClick={() => editCategory(record)}
            >
              <EditOutlined style={{ fontSize: 20, color: "#2D2D2D" }} />
            </Button>
            <Popconfirm
              placement="top"
              title="Tem certeza que deseja eliminar este e-mail?"
              onConfirm={() => deleteGroupEmail(record)}
              okText="Sim"
              cancelText="Não"
              okType="link"
              okButtonProps={{
                className: "pop-confirm-confirm-btn",
              }}
              cancelButtonProps={{
                className: "pop-confirm-cancel-btn",
              }}
              icon={<WarningOutlined style={{ color: "#E70000" }} />}
              rootClassName="pop-confirm"
            >
              <Button type="link">
                <DeleteOutlined
                  style={{ fontSize: 20, color: "rgba(255, 0, 0, 0.6)" }}
                />
              </Button>
            </Popconfirm>
          </div>
        ) : (
          <div>
            <Button
              type="link"
              onClick={() => updateGroupEmail(record)}
            >
              <SaveOutlined style={{ fontSize: 20, color: "#2D2D2D" }} />
            </Button>
            <Button type="link" onClick={() => setEditingKey("")}>
              <CloseOutlined
                style={{ fontSize: 20, color: "rgba(255, 0, 0, 0.6)" }}
              />
            </Button>
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
<div className="external-group-tab">
      <Form form={formAdd} onFinish={createGroupEmail}>
        <Row gutter={16} className="form-col">
          <Col span={12}>
            <Form.Item
              name="email"
              rules={[{ required: true, type: "email", message: "Insira um email válido" }]}
            >
              <Input
                placeholder="Novo e-mail"
                className="input-default"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={loadingAddData}
              >
                Adicionar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Form form={formEdit} component={false}>
        <Table
          className="external-group-tab-table"
          rowClassName="editable-row"
          columns={mergedColumns}
          dataSource={data}
          sticky={true}
          pagination={false}
          loa
          rowKey="uid"
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          loading={loadingData}
        />
      </Form>
    </div>
  );
};

export default ExternalArticlesTab;
