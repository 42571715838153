import React from "react";

import {Row, Col} from "antd"

import "./index.less"

const CentralizedRaizLogo = () => {
  return(
    <Row align="middle" justify="center" style={{marginTop: 32}}>
      <Col>
        <img style={{maxWidth: 100}} src="images/logo.png" alt="Logo Raiz" />
      </Col>
    </Row>
  )
}

export default CentralizedRaizLogo