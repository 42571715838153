import React, { forwardRef, useState, useEffect, useImperativeHandle, useRef } from "react";

import { Row, Col, Upload, Button } from "antd";
import { ConsoleSqlOutlined, FilePdfOutlined } from '@ant-design/icons';
import jsPDF from "jspdf";
import 'jspdf-autotable';
import html2canvas from 'html2canvas';

import _service from "@netuno/service-client";

import "./index.less";

const PDFExport = forwardRef((props, ref) => {
    const data = props.data;
    const post = data.post;

    const [area, setArea] = useState(null);
    const [categories, setCategories] = useState([]);
    const [trl, setTRL] = useState(null);

    useEffect(() => {
        setArea(data.area);
        setCategories(data.categories);
        setTRL(data.trl);
    }, [props]);

    useImperativeHandle(ref, () => ({
        printPDF
    }));

    const formatDate = (dateString) => {
        const dateArray = dateString.split('-');
        const year = dateArray[0];
        const month = dateArray[1];
        const day = dateArray[2];

        return `${day}/${month}/${year}`;
    }

    const printPDF = async (post) => {
        const pdf = new jsPDF("p", "px", "a4");
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfPageSizeWidth = pdf.internal.pageSize.width;
        const pdfPageSizeHeight = pdf.internal.pageSize.height;
        const pageSidePadding = 10;

        // Banner Image
        const bannerElement = document.getElementById(`banner-export-${post.uid}`);
        const canvas = await html2canvas(bannerElement, {
            logging: true,
            letterRendering: 1,
            useCORS: true,
            onclone: (clone) => {
                const divToShow = clone.querySelector(`#post-export-pdf-${post.uid}`);
                divToShow.style.display = 'block';
            },
        });
        const img = new Image();
        img.src = canvas.toDataURL('image/png');
        pdf.addImage(img, 0, 0, pdfPageSizeWidth, 150);

        // Banner Title
        const text = post.title;
        const textWidth = pdf.getStringUnitWidth(text) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
        const textXPos = pdfWidth / 2 - textWidth / 2;
        pdf.setTextColor(255, 255, 255);
        pdf.text(textXPos, 75, text);
        pdf.setTextColor(0, 0, 0);

        // HTML Content
        const htmlContent = await generateHTMLContent(post, pdfPageSizeWidth);
        //document.getElementById(`post-export-pdf-${post.uid}`).style.display = 'block';
        //const htmlContent = document.getElementById(`content-export-${post.uid}`);

        const wrapper = document.createElement('div');
        wrapper.innerHTML = htmlContent;
        let spaceUsed = 150;
        let spaceRemaining = pdfPageSizeHeight - 150;

        // Categories
        const categoriesDiv = wrapper.querySelector(`.category`);
        const totalCategoryButtons = categoriesDiv.querySelectorAll('button').length;
        const totalCategoryHeight = ((22.5 * totalCategoryButtons) + (3 * totalCategoryButtons));
        await PDFAddHtml(pdf, categoriesDiv.outerHTML, 10, 150, true);
        spaceUsed += totalCategoryHeight;
        spaceRemaining -= totalCategoryHeight;

        // Date
        const dateDiv = wrapper.querySelector(`.date`);
        await PDFAddHtml(pdf, dateDiv.outerHTML, 10, 150 + totalCategoryHeight, true);
        spaceUsed += 31;
        spaceRemaining -= 31;

        // Content
        const contentDiv = wrapper.querySelector(`#content`);
        const wrapperContent = document.createElement('div');
        wrapperContent.innerHTML = contentDiv.innerHTML;


        const elements = Array.from(wrapperContent.childNodes);
        const lastIndex = elements.length - 1;

        let testHTML = "";

        const processElement = async (element) => {
            const divWidth = pdfPageSizeWidth - pageSidePadding;

            const wrapperCustomElem = document.createElement('div');
            wrapperCustomElem.innerHTML = element.outerHTML;
            wrapperCustomElem.style.margin = "0";
            wrapperCustomElem.style.padding = "0";
            wrapperCustomElem.style.width = `${divWidth.toString()}px`;
            wrapperCustomElem.style.letterSpacing = "0.01px";

            const customElem = wrapperCustomElem.firstChild;
            const tagName = customElem.tagName.toLowerCase();
        
            if (tagName === "h1") {
                //customElem.style.maxWidth = `${pdfPageSizeWidth.toString()}px`;
                customElem.style.fontSize = "16px";
                customElem.style.lineHeight = "16px";
            } else if (tagName === "h2") {
                customElem.style.fontSize = "12px";
                customElem.style.lineHeight = "12px";
            } else if (tagName === "h3") {
                customElem.style.fontSize = "9px";
                customElem.style.lineHeight = "9px";
            } else if (tagName === "h4") {
                customElem.style.fontSize = "8px";
                customElem.style.lineHeight = "8px";
            } else {
                customElem.style.fontSize = "7px";
                customElem.style.lineHeight = "7px";
                customElem.style.padding = "0";
                customElem.style.margin = "0";
            }

            const childNodes = customElem.childNodes;
            for (let i = 0; i < childNodes.length; i++) {
              const child = childNodes[i];
              if (child.nodeType === 1) {
                child.style.color = "black";
                child.style.backgroundColor = "transparent";
              }
            }
            let elementOuterHtml = wrapperCustomElem.outerHTML;
            let elementHeight = getElementHeight(elementOuterHtml);

            let isPadding = false;
            /**if (!element.outerHTML) {
                console.log("Não tem element disponivel");
            }*/

            if (element.outerHTML === "<p><br></p>" || element.outerHTML === '<p class="ql-align-justify"><br></p>') {
                isPadding = true;
            }

            // Check if Element is image
            const checkHasImg = await hasImg(element.outerHTML);
            const isImg = checkHasImg.result;

            return new Promise((resolve, reject) => {
                if (element.outerHTML && isPadding == false && isImg == false) {
                    testHTML += elementOuterHtml;                    

                    if (spaceRemaining <= elementHeight) {
                        // Add New page

                        pdf.addPage();
                        setTimeout(() => {
                            spaceUsed = 10;
                            spaceRemaining = pdf.internal.pageSize.height;

                            PDFAddHtml(pdf, elementOuterHtml, 10, spaceUsed, false)
                                .then((result) => {
                                    spaceUsed += elementHeight;
                                    spaceRemaining -= elementHeight;
                                    resolve(result);
                                })
                                .catch((error) => {
                                    reject(error);
                                });
                        }, 2500);
                    } else {
                        PDFAddHtml(pdf, elementOuterHtml, 10, spaceUsed, false)
                            .then((result) => {
                                spaceUsed += elementHeight;
                                spaceRemaining -= elementHeight;
                                resolve(result);
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    }
                } else if (isImg) {
                    // Add Image
                    const img = checkHasImg.img;
                
                    const imgProps = pdf.getImageProperties(img.src);
                    const imgWidth = imgProps.width;
                    const imgHeight = imgProps.height;
    
                    // Check if width
                    const regexWidth = /width="(.*?)"/;
                    const matchWidth = regexWidth.exec(element.outerHTML);
                    const widthValue = matchWidth ? matchWidth[1] : null;
    
                    let isPercentage = false;
                    let ImgPercentage = 1;
                    if (widthValue.endsWith('%')) {
                        isPercentage = true;
                        ImgPercentage = img.width / 100;
                    }
    
                    // Calculate Image/PDF Width and Height scale
                    let pageImgWidth = pdfPageSizeWidth - (pageSidePadding * 2);
                    const targetWidth = isPercentage ? pageImgWidth * ImgPercentage :  pageImgWidth;
                    const scaleFactor = targetWidth / imgWidth;
                    const scaledWidth = imgWidth ? imgWidth * scaleFactor : pageImgWidth * scaleFactor;
                    const scaledHeight = imgHeight * scaleFactor;
    
                    // Image Margin
                    const imgMarginValue = img.style.margin;
    
                    let imageMargin = 10;
                    if (imgMarginValue == "auto") {
                        imageMargin = ( (pdfPageSizeWidth - pageSidePadding) - scaledWidth) / 2;

                        if (imageMargin < 10) {
                            imageMargin = 10;
                        }
                    }

                    if ((spaceRemaining + pageSidePadding) <= scaledHeight) {
                        // Add new page
    
                        pdf.addPage();
                        setTimeout(() => {
                            pdf.addImage(img.src, 'JPEG', ImgPercentage ? imageMargin : 20, 10, scaledWidth, scaledHeight);

                            spaceUsed = scaledHeight + 20;
                            spaceRemaining = pdf.internal.pageSize.height - spaceUsed;

                            resolve();
                        }, 2500);
                    } else {                        
                        pdf.addImage(img.src, 'JPEG', imageMargin, spaceUsed + pageSidePadding, scaledWidth, scaledHeight);
                        
                        spaceUsed += scaledHeight + 10;
                        spaceRemaining -= (scaledHeight + 10);
                        resolve();
                    }
                } else {
                    resolve();
                }
            });
        };

        const processElements = async () => {
            for (const element of elements) {
                try {
                    await processElement(element);
                } catch (error) {
                    console.error('Error processing element:', error);
                }
            }
            pdf.save(`${post.uid}.pdf`);
        };
        processElements();
    }

    const PDFAddHtml = async (pdf, html, width, height, autoPaging) => {
        await new Promise((resolve) => {
            pdf.html(html, {
                x: width,
                y: height,
                autoPaging,
                callback: function (pdf) {
                    resolve();
                }
            });
        });
    }


    const hasImg = async (element) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(element, 'text/html');
        const imgElements = doc.getElementsByTagName('img');

        return {
            result: imgElements.length > 0,
            img: imgElements.length > 0 ? imgElements[0] : null
        };
    }

    const getElementHeight = (html) => {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = html;
        tempElement.style.position = 'absolute';
        tempElement.style.visibility = 'hidden';

        document.body.appendChild(tempElement);
        const height = tempElement.offsetHeight;
        document.body.removeChild(tempElement);

        return height;
    }

    const generateHTMLContent = (post, pageWidth) => {
        let categoriesHTML = "";
        categories.map((category) => {
            categoriesHTML += ('<button style="width: max-content; display: flex; flex-direction: row; justify-content: center; align-items: left; padding: 4px 8px; gap: 10px; background-color: #ffffff; border: 1px solid #2D2D2D; border-radius: 100px; height: 22.5px;">' +
                '<span style="padding-left: 1px; padding-bottom: 2px; font-weight: 400; font-size: 8px; line-height: 10px; display: flex; align-items: center; text-align: center;">' + category.title + ': ' + category.rank + '</span>'
                + '</button>')
        })
        let htmlContent = `
        <div class="category" style="font-size: 8px; max-width: calc(${pageWidth.toString()}px/1.10); width: ${pageWidth.toString()}px; margin: 10px 0px 5px 0px; display: flex; flex-direction: column; align-items: left; padding: 0px; gap: 4px 8px; flex-wrap: wrap; height: 100%;">
            ${area ? (
                '<button style="width: max-content; display: flex; flex-direction: row; justify-content: center; align-items: left; padding: 4px 8px; gap: 10px; background-color: #ffffff; border: 1px solid #2D2D2D; border-radius: 100px; height: 22.5px;">' +
                '<span style="padding-left: 1px; padding-bottom: 2px; font-weight: 400; font-size: 8px; line-height: 10px; display: flex; align-items: center; text-align: center;">' + area + '</span>'
                + '</button>'
            ) : ""}
            ${categoriesHTML}
            ${trl ? (
                '<button style="width: max-content; display: flex; flex-direction: row; justify-content: center; align-items: left; padding: 4px 8px; gap: 10px; background-color: #ffffff; border: 1px solid #2D2D2D; border-radius: 100px; height: 22.5px;">' +
                '<span style="padding-left: 1px; padding-bottom: 2px; font-weight: 400; font-size: 8px; line-height: 10px; display: flex; align-items: center; text-align: center;">Prontidão tecnológica vs Tempo para o mercado: ' + trl + '</span>'
                + '</button>'
            ) : ""}
            ${post['metadata'].strategic ? (
                '<button style="width: max-content; display: flex; flex-direction: row; justify-content: center; align-items: left; padding: 4px 8px; gap: 10px; background-color: #ffffff; border: 1px solid #2D2D2D; border-radius: 100px; height: 22.5px;">' +
                '<span style="padding-left: 1px; padding-bottom: 2px; font-weight: 400; font-size: 8px; line-height: 10px; display: flex; align-items: center; text-align: center;">Estratégico</span>'
                + '</button>'
            ) : ""}
            ${post['metadata'].is_external ? (
                '<button style="width: max-content; display: flex; flex-direction: row; justify-content: center; align-items: left; padding: 4px 8px; gap: 10px; background-color: #ffffff; border: 1px solid #2D2D2D; border-radius: 100px; height: 22.5px;">' +
                '<span style="padding-left: 1px; padding-bottom: 2px; font-weight: 400; font-size: 8px; line-height: 10px; display: flex; align-items: center; text-align: center;">Externo</span>'
                + '</button>'
            ) : ""}
        </div>
        <div class="date" style="margin: 14px 0; max-width: calc(${pageWidth.toString()}px/1.10); width: ${pageWidth.toString()}px;font-weight: 400; font-size: 8px; line-height: 11px;"><i>Em ${formatDate(post.date)}</i></div>
        <div id="content" style="font-weight: 400; font-size: 7px; line-height: 10px; max-width: calc(${pageWidth.toString()}px/1.10); width: ${pageWidth.toString()}px;">
            ${post['structure'].length > 0 ? (
                post['structure'][0].content
            ) : ""}
        </div>
        `;

        //console.log(htmlContent)

        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, "text/html");
        doc.querySelectorAll("img").forEach(img => {
            img.style.maxWidth = "100%";
        });
        htmlContent = doc.body.innerHTML;

        return htmlContent;
    }

    const styleMaxWidth = {
        maxWidth: "100%",
        position: "fixed",
        width: "100%",
        left: "0"
    }

    return (
        <div style={{ display: "", ...styleMaxWidth }}>
            <div id={`post-export-pdf-${post.uid}`} style={{ display: 'none' }}>
                <div className="banner" id={`banner-export-${post.uid}`}>
                    <div className="image" id="image-export" style={{
                        backgroundImage: `url(${post.featured_image ? post.featured_image : "/images/imageartigo.jpg"})`
                    }}>
                        <div className="banner__darken-bg"></div>
                    </div>
                </div>
                <div id={`content-export-${post.uid}`} dangerouslySetInnerHTML={{ __html: generateHTMLContent(post, "446px") }}></div>
            </div>
        </div>
    );
});

export default PDFExport;
