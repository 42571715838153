import React, { useState } from 'react';

import { Modal, Form, Input, Button, notification } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

import _auth from '@netuno/auth-client';
import _service from '@netuno/service-client';

import "./index.less";

const LoginModal = ({ show, onClose }) => {

  const [loadingLogin, setLoadingLogin] = useState(false);

  const handleLoginSubmit = (values) => {
    const { username, password } = values;

    setLoadingLogin(true);

    _auth.login({
      username,
      password,
      success: () => {
        setLoadingLogin(false);
        onClose();
        window.location.reload();
      },
      fail: () => {
        setLoadingLogin(false);
        notification["error"]({
          message: "Login Inválido",
          description: "Por favor verifique as credenciais inseridas.",
        });
      },
    });
  }

  return (
    <Modal className="login-modal" open={show} onCancel={onClose} footer={null} destroyOnClose={true}>
      <span className="login-modal--title">Faça Login</span>
      <Form className="login-modal--form" onFinish={handleLoginSubmit}>
        <Form.Item name="username" rules={[{ required: true, message: '' }]}>
          <Input prefix={<UserOutlined />} placeholder="Utilizador" />
        </Form.Item>
        <Form.Item name="password" rules={[{ required: true, message: '' }]}>
          <Input.Password prefix={<LockOutlined />} placeholder="Senha" />
        </Form.Item>
        <Form.Item>
          <Button
            className="login-modal--form--item--submit-btn"
            type="primary"
            htmlType="submit"
            loading={loadingLogin}
          >
            Entrar
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default LoginModal;
