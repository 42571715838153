import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import sal from "sal.js";

import Banner from "../components/Banner";
import Content from "../components/Content";
import Listing from "../components/Listing";
import ContactForm from "../components/functionality/ContactForm";
import ContactMap from "../components/functionality/ContactMap";
//import Highlights from "../components/functionality/Highlights";
import PostList from "../components/functionality/PostList";
import Post from "../components/functionality/Post";
import CreatePost from "../components/functionality/CreatePost";
import PostsManagement from "../components/functionality/PostsManagement";
import RadarChart from "../components/functionality/RadarChart";
import CentralizedRaizLogo from "../components/functionality/CentralizedRaizLogo";

import _service from "@netuno/service-client";
import _auth from "@netuno/auth-client";

function Builder({ page, type = "page" }) {
  const navigate = useNavigate();
  const location = useLocation();
  const path = useParams();
  const [pageData, setPageData] = useState(page);
  const [components, setComponents] = useState([]);
  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    setPageData(page);
  }, [page]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const postData = await getPostData(path['*']);

        setPageData(postData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (type === "post") {
      fetchData();
    }
  }, [path]);

  const getPostData = (path) => {
    return new Promise((resolve, reject) => {
      _service({
        url: "/post",
        method: 'GET',
        data: {
          link: `/${path}`,
        },
        success: (response) => {
          const jsonData = response.json;

          if (jsonData.result) {
            const data = jsonData['data'];
            resolve(data);
          }

          resolve();
        },
        fail: (e) => {
          console.log("Service failed:", e);
          resolve();
        }
      });
    });
  }

  let isSearch = false;
  if (page && page.link === "/pesquisa") {
    isSearch = true;

    if (searchString !== location.state?.searchString) {
      setSearchString(location.state?.searchString);

      if (searchString === "" || searchString === null) {
        navigate(`/`, { replace: true });
      }
    } else if (searchString === "") {
      navigate(`/`, { replace: true });
    }
  }

  useEffect(() => {
    //console.log("pageDATA")
    //console.log(pageData);

    if (pageData) {
      sal();
      document.getElementsByTagName("meta")["keywords"].content = pageData.keywords ? pageData.keywords : "";
      document.getElementsByTagName("meta")["description"].content = pageData.description ? pageData.description : "";
      window.scrollTo(0, 0);

      if ((pageData.link === "/criar-artigo"
        || pageData.link === "/editar-artigo"
        || pageData.link === "/gerir-artigos") && !_auth.isLogged()) {
        setComponents([]);
        navigate(`/`, { replace: false });

        return;
      }

      let componentsElements = [];
      for (const item of pageData.structure) {
        const { uid } = item;
        if (item.section === "banner") {
          componentsElements.push(<Banner key={uid} {...item} />);
        } else if (item.section === "content") {
          componentsElements.push(<Content key={uid} {...item} />);
        } else if (item.section === "listing") {
          componentsElements.push(<Listing key={uid} {...item} />);
        } else if (item.section === "functionality") {
          if (item.type === "contact-form") {
            componentsElements.push(<ContactForm key={uid} {...item} />);
          } else if (item.type === "contact-map") {
            componentsElements.push(<ContactMap key={uid} {...item} />);
          } else if (item.type === "post-list") {
            componentsElements.push(<PostList key={uid} {...item} isSearch={isSearch} searchString={isSearch ? searchString : null} />);
          } else if (item.type === "post-create") {
            componentsElements.push(<CreatePost key={uid} {...item} isEditing={pageData.link === "/editar-artigo" ? true : false} />);
          } else if (item.type === "posts-management") {
            componentsElements.push(<PostsManagement key={uid} {...item} />);
          } else if (item.type === "radar-chart") {
            componentsElements.push(<RadarChart key={uid} {...item} />);
          } else if (item.type === "centralized-raiz-logo") {
            componentsElements.push(<CentralizedRaizLogo key={uid} {...item} />);
          }
        }
      }

      if (type === "post") {
        componentsElements = (<Post data={pageData} components={componentsElements} />);
      }

      setComponents(componentsElements);
    }

  }, [pageData, searchString]);

  return <main>{components}</main>;
}
export default Builder;
