import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "antd";
import { FilePdfOutlined } from '@ant-design/icons';
import sal from "sal.js";

import _service from "@netuno/service-client";

import PDFExport from "../../PDFExport";

import "react-quill/dist/quill.core.css";
import "./index.less";

const Post = (props) => {
  const componentRef = React.createRef();
  const exportPDFRef = React.useRef();

  const post = props.data;
  const postMetadata = post['metadata'] ? post['metadata'] : []; 

  const [area, setArea] = useState(null);
  const [trl, setTRL] = useState(null);
  
  useEffect(() => {
    window.scrollTo(0, 0);
    sal();

    getArea();
    getTRL();
    addVisualization();
  }, []);

  const addVisualization = () => {
    _service({
      url: "/post/add-visualization",
      method: 'POST',
      data: {
        uid: post.uid,
      },
      success: (response) => {

      },
      fail: (e) => {
        console.log("Service failed:", e);
      }
    });
  }

  const getArea = () => {
    if (postMetadata.area) {
      fetch(`/data/post-areas.json?time=${new Date().getTime()}`)
        .then((response) => response.json())
        .then((data) => {
          const areaData = data[postMetadata.area];

          setArea(areaData.name);
        });
    }
  };

  const getTRL = () => {
    if (postMetadata.trl) {
      fetch(`/data/post-trl.json?time=${new Date().getTime()}`)
        .then((response) => response.json())
        .then((data) => {
          const trlData = data[postMetadata.trl];

          setTRL(trlData.code);
        });
    }
  };

  const formatDate = (dateString) => {
    const dateArray = dateString.split('-');
    const year = dateArray[0];
    const month = dateArray[1];
    const day = dateArray[2];

    return `${day}/${month}/${year}`;
  }

  return (
    <Row className="post" ref={componentRef}>
      <PDFExport ref={exportPDFRef} data={{ post, area, categories: post['category'], trl, components: props.components}} style={{display: "none"}} />
      <div className="banner">
        <div className="image" style={{
          backgroundImage: `url(${post.featured_image ? post.featured_image : "/images/imageartigo.jpg"})`,
        }}>
          <Row className="banner__wrapper" justify="center">
            <Col lg={12} sm={24}>
              <div>
                <h1 data-sal="slide-down" data-sal-duration="2000" data-sal-easing="ease-out-cubic">{post.title}</h1>
                <Button className="export-pdf" icon={<FilePdfOutlined />} onClick={() => exportPDFRef.current?.printPDF(post)}>Exportar artigo em PDF</Button>
              </div>
            </Col>
          </Row>
          <div className="banner__darken-bg"></div>
        </div>
      </div>
      <div className="content-main ql-editor">
        <div className="text">
          <div className='category'>
            {area ? (
              <button>
                <span>{area}</span>
              </button>
            ) : null
            }
            {post.category.map((category) => (
              <button key={category.slug}>
                <span>{category.title}: {category.rank}</span>
              </button>
            ))}
            {trl ? (
              <button>
                <span>Prontidão tecnológica vs Tempo para o mercado: {trl}</span>
              </button>
            ) : null
            }
            {post['metadata'].strategic ?
              (
                <button>
                  <span>Estratégico</span>
                </button>
              )
              : null
            }
            {post['metadata'].is_external ?
              (
                <button>
                  <span>Externo</span>
                </button>
              )
              : null
            }
          </div>
          <div className='date'><i>Em {formatDate(post.date)}</i></div>
          {props.components}
        </div>
      </div>
    </Row>
  );
};

export default Post;
